import _ from 'lodash';
import {GamesUserReport} from "./Games.UserReport";


class GamesUserLtzReport extends GamesUserReport {
    getMetrics() {
        return super.getMetrics().map(metric => `${metric}_ltz`);
    }

    getMetricSpecificApiParams(metric) {
        return super.getMetricSpecificApiParams(metric.replace('_ltz', ''));
    }

    updateKeysWithLtz(obj) {
        return _.reduce(obj, function(result, value, key) {
            result[`${key}_ltz`] = value;
            return result;
        }, {});
    }

    getMetricHeader() {
        return this.updateKeysWithLtz(super.getMetricHeader());
    }

    getMetricFormatter() {
        return this.updateKeysWithLtz(super.getMetricFormatter());
    }
}

export {GamesUserLtzReport};
