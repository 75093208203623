import React from 'react';
import echarts from 'echarts';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import {Utils} from "../common/utils";
import {Properties} from "../common/properties";
import {RealtimeDatepicker} from "../components/Datepicker";
import * as moment from "moment-timezone";
import {Report} from "./Report"

import "../assets/css/RealtimeReport.css"
import Button from "@material-ui/core/Button";

const LINE_COLORS = ['#1B92F5', '#FF3B30', '#FFB000'];

class RealtimeReport extends Report {

    constructor(props) {
        super(props);
        this.charts = [];
    }

    getConstructorStates() {
        return {
            chartOptions: [],
            dates: [this.getNow(), this.getNow().subtract(1, 'days'), this.getNow().subtract(7, 'days')],
            width: window.innerWidth,
            ...super.getConstructorStates()
        };
    }

    componentDidMount() {
        super.componentDidMount();
        window.onresize = () => this.setState({width: window.innerWidth});
        this.autoRefresh = setInterval(() => {
            if (!document.hidden) {
                this.getAndProcessData();
            }
        }, 60000);
        document.onvisibilitychange = this.refreshOnOpeningTab()
    }

    refreshOnOpeningTab() {
        let lastRefreshTime = this.getNow();
        return () => {
            let now = this.getNow();
            let timePassedInSeconds = moment.duration().subtract(lastRefreshTime.diff(now)).asSeconds();
            if (!document.hidden && timePassedInSeconds > 60.0) {
                lastRefreshTime = now;
                this.getAndProcessData();
            }
        }
    }

    componentWillUnmount() {
        window.onresize = () => null;
        clearInterval(this.autoRefresh);
        document.onvisibilitychange = () => null;
    }

    getFinishedLoadingState(errorMessage) {
        return {
            chartOptions: this.makeChartOptions(),
            ...super.getFinishedLoadingState(errorMessage)};
    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        super.componentDidUpdate(prevProps, prevState, snapShot);
        if (this.state.width !== prevState.width) {
            this.setState({chartOptions: this.makeChartOptions()});
        }
        let currentlyDisplayed = chartOption => this.state.displayRegions[chartOption.region];
        let previouslyDisplayed = chartOption => prevState.displayRegions[chartOption.region];

        if (!_.isEqual(prevState.chartOptions, this.state.chartOptions)) {
            _.forEach(this.state.chartOptions, (chartOption, index) => {
                if (currentlyDisplayed(chartOption)) {
                    this.redrawChartWithIndex(chartOption, index);
                }
            });
            return
        }

        if (!_.isEqual(prevState.displayRegions, this.state.displayRegions)) {
            _.forEach(this.state.chartOptions, (chartOption, index) => {
                if (currentlyDisplayed(chartOption) && !previouslyDisplayed(chartOption)) {
                    this.redrawChartWithIndex(chartOption, index);
                }
            });
        }
    }

    redrawChartWithIndex(chartOption, index) {
        this['charts'][index].resize();
        this['charts'][index].clear();
        this['charts'][index].setOption(chartOption);
    }

    getDates() {
        return _.uniq(this.state.dates.map(date => Utils.formatMomentForApi(date, this.getFrequencyBit())));
    }

    getFrequencyBit() {
        return Properties.frequencyBit.realtime;
    }

    getReportSpecificApiParams() {
        let params = super.getReportSpecificApiParams();
        return {
            date: this.getDates(),
            ...params,
        }
    }

    makeChartOptions() {
        let dates = this.getDates();
        let chartOptions = [];

        this.getSubgroups().forEach(subgroup => {
            let subgroupKey = subgroup.key;

            let legendDates = [];
            let series = [];
            let xAxis = [];
            let scaleYAxis = false;

            dates.forEach(date => {
                let seriesParams = this.makeSeriesParams(date, subgroupKey);
                legendDates.push(...seriesParams.legendDates);
                series.push(...seriesParams.items);

                if (!_.isEmpty(seriesParams.xAxis)) {
                    xAxis = seriesParams.xAxis;
                    scaleYAxis = seriesParams.scaleYAxis;
                }
            });
            let chartOption = this.makeChartOption(series, legendDates, xAxis, subgroup, scaleYAxis);
            chartOptions.push(chartOption);
        });

        return chartOptions;
    }

    makeSeriesParams(date, regionKey) {
        let metric = this.getMetrics()[0];
        let dataDictDataKey = this.makeDataDictKey(metric, this.getDataKey(), date, regionKey, this.getDefaultPeriod());
        let dataDictMetaKey = this.makeDataDictKey(metric, this.getMetaKey(), date, regionKey, this.getDefaultPeriod());
        let data = this.state.dataDict[dataDictDataKey];
        let metaData = this.state.dataDict[dataDictMetaKey];

        if (data === undefined || metaData === undefined) {
            return {
                xAxis: [],
                items: [],
                legendDates: [],
                scaleYAxis: false
            };
        }

        let seriesItems = [];
        let breakPoints = this.getBreakPointIndexes(data, metaData);
        let isTimezoneFasterThanSG = _.get(metaData, 'start_of_day', 0) < 0;

        breakPoints.forEach((breakPoint, i) => {
            let lineStartIndex = (i === 0 || breakPoints[i - 1] === 0 ? 0 : breakPoints[i - 1] + 1);
            if (breakPoint === lineStartIndex) {
                return;
            }

            let seriesItemId = this.getSeriesItemId(breakPoints.length, i, date, isTimezoneFasterThanSG);
            seriesItems.push(this.makeSeriesItem(data, lineStartIndex, breakPoint, seriesItemId, date));
        });

        return {
            xAxis: data.map(datum => Utils.getTimestampFromMinute(datum.time)),
            items: seriesItems,
            legendDates: [date],
            scaleYAxis:metaData.normalize_y_axis
        };
    }

    getBreakPointIndexes(data, metaData) {
        let startOfDay = _.get(metaData, 'start_of_day', 0);
        if (startOfDay < 0) {
            startOfDay += 1440; // minutes in one day
        }
        return [
            _.findIndex(data, datum => datum.time === startOfDay),
            data.length - 1,
        ];
    }

    getSeriesItemId(breakPointAmount, index, date, isTimezoneFasterThanSG) {
        let prefix = '';
        if (breakPointAmount === 2) {
            if (index === 0 && !isTimezoneFasterThanSG) {
                let updatedDates = Utils.formatMomentForApi(moment(date).subtract(1, 'days'), this.getFrequencyBit());
                prefix = `${updatedDates}_`;
            } else if (index === 1 && isTimezoneFasterThanSG) {
                let updatedDates = Utils.formatMomentForApi(moment(date).add(1, 'days'), this.getFrequencyBit());
                prefix = `${updatedDates}_`;
            }
        }
        return `${prefix}${date}`;
    }

    makeSeriesItem(data, startIndex, endIndex, seriesItemId, date) {
        let nullifyValue = (datum) => {
            let newDatum = _.cloneDeep(datum);
            newDatum.value = null;
            return newDatum;
        };

        let seriesItemData = data.map((datum, index) => {
            if (startIndex <= index && index <= endIndex) {
                return datum;
            }
            return nullifyValue(datum);
        });

        let dateIndex = this.getDates().indexOf(date);
        let seriesItem = {
            name: date,
            id: seriesItemId,
            type: 'line',
            animation: false,
            data: seriesItemData,
            z: 4 - dateIndex,  // To keep everything above 0
            symbol: 'none',
            itemStyle: {
                color: LINE_COLORS[dateIndex]
            }
        };

        if (this.isDateToday(date)) {
            seriesItem = this.addMarkPointToSeriesItem(seriesItem);
        }
        seriesItem.data = seriesItem.data.map(datum => datum.value);

        return seriesItem;
    }

    isDateToday(date) {
        return date === Utils.formatMomentForApi(this.getNow(), this.getFrequencyBit());
    }

    addMarkPointToSeriesItem(seriesItem) {
        let nonNullData = seriesItem.data.filter(datum => datum.value !== null);
        if (_.isEmpty(nonNullData)) {
            return seriesItem;
        }

        let nowDatum = _.last(nonNullData);
        let peakDatum = _.maxBy(seriesItem.data, datum => datum.value);

        let markPointData = [
            this.makeMarkPointDatum(nowDatum, 'now'),
        ];

        if (this.needToDisplayPeak(nowDatum, peakDatum)) {
            markPointData.push(this.makeMarkPointDatum(peakDatum));
        }

        seriesItem.markPoint = {
            symbol: 'rect',
            symbolSize: 1,
            label: {
                backgroundColor: '#FFFFFF',
                borderColor: seriesItem.itemStyle.color,
                borderRadius: 9,
                borderType: 'solid',
                borderWidth: '1px',
                color: seriesItem.itemStyle.color,
                fontFamily: 'Open Sans',
                fontSize: 10,
                fontWeight: 600,
                offset: [0, 0],
                padding: [
                    3.5,
                    5.5,
                    2.5,
                    5.5
                ],
                position: 'top',
            },
            data: markPointData
        };

        return seriesItem;
    }

    makeMarkPointDatum(datum, name=null) {
        const dp = _.get(this.getValueProps(), 'decimal_point', 0)

        let markPointDatum = {
            value: Utils.formatCommaSeparatedNumber(datum.value, dp),
            coord: [Utils.getTimestampFromMinute(datum.time), datum.value]
        };
        if (name !== null) {
            markPointDatum.name = name;
        }
        return markPointDatum;
    }

    needToDisplayPeak(nowDatum, peakDatum) {
        return peakDatum.value !== nowDatum.value && peakDatum.time <= 180;
    }

    makeChartOption(series, legendDates, xAxis, subgroup, scaleYAxis) {
        return {
            region: this.getRegionKeyFromSubgroup(subgroup),
            title: this.getChartTitle(subgroup.label),
            legend: this.getChartLegend(legendDates),
            grid: this.getChartGrid(),
            tooltip: this.getChartTooltip(),
            xAxis: this.getChartXAxis(xAxis),
            yAxis: this.getChartYAxis(scaleYAxis),
            series
        };
    }

    getChartTitle(label) {
        return {
            borderColor: '#E3E8F6',
            borderRadius: 2,
            borderWidth: 1,
            left: -2,
            padding: [
                18,
                22,
                14,
                22
            ],
            text: label,
            textStyle: {
                fontFamily: 'Open Sans',
                fontSize: 16,
                fontWeight: 600
            },
            top: -2
        };
    }

    getChartLegend(legendDates) {
        let chartWidth = this.getChartWidth(this.state.width);
        let itemGap = Math.max(30, (chartWidth - 500) / 3);  // This is 'magic number' to look nice
        return {
            data: legendDates,
            itemGap: itemGap,
            left: 'right',
            padding: [
                5,
                itemGap === 30 ? 24 : 55,
                5,
                5
            ],
            top: 45,
            textStyle: {
                fontFamily: 'Open Sans',
                fontSize: 12,
                fontWeight: 400
            },
            type: 'scroll',
        };
    }

    getChartWidth(screenWidth) {
        let reportWidth = document.getElementsByClassName('realtime-report__body')[0].offsetWidth;
        if (screenWidth >= 1280) {
            reportWidth -= 40;
            reportWidth /= 2;
        }
        return reportWidth;
    }

    getChartGrid() {
        return {
            bottom: 49,
            left: 67,
            right: 40,
            top: 80,
        }
    }

    getChartTooltip() {
        const dp = _.get(this.getValueProps(), 'decimal_point', 0)
        let tooltipFormatter = (params) => {
            let paramStrings = [];
            let tick = '';

            params.forEach(p => {
                if (p.value === undefined) {
                    return;
                }
                let seriesDate = p.seriesId.split('_')[0];
                let paramString = `${seriesDate}: ${Utils.formatCommaSeparatedNumber(p.value, dp)}`;
                paramStrings.push(paramString);
                tick = p.axisValueLabel;
            });
            let tooltipString = paramStrings.join('<br/>');
            return `${tick}<br/> ${tooltipString}`;
        };

        return {
            trigger: 'axis',
            formatter: tooltipFormatter
        };
    }

    getChartXAxis(xAxis) {
        return {
            boundaryGap: false,
            axisLabel: {
                fontFamily: 'Open Sans',
                fontSize: 10,
                fontWeight: 400
            },
            axisLine: {
                show: false
            },
            axisTick: {
                lineStyle: {
                    color: '#E3E8F6'
                }
            },
            data: xAxis,
        }
    }

    getChartYAxis(scaleYAxis) {
        const valueProps = this.getValueProps()
        const prefix = _.get(valueProps, 'prefix', '')
        const suffix = _.get(valueProps, 'suffix', '')
        const formatter = (val) => {
            const sign = val >= 0? 1: -1
            const absFormattedVal = Utils.formatNumberShortform(Math.abs(val))
            let res = prefix + absFormattedVal + suffix
            res = sign === -1 ? '-' + res : res
            return res

        }
        return {
            axisLabel: {
                fontFamily: 'Open Sans',
                fontSize: 12,
                fontWeight: 400,
                formatter: formatter,
                margin: 16
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                lineStyle: {
                    color: '#E3E8F6'
                }
            },
            scale: scaleYAxis,
        }
    }

    onDateChange(i, date) {
        let dates = this.state.dates;
        dates[i] = date;
        this.setState({
            dates
        })
    }

    makeReport() {
        let margin = (
            (<div className={'realtime-report__margin'} key={'realtime-report__margin'}/>)
        );
        let charts = [];
        this['charts'] = [];
        _.forEach(this.getSubgroups(), (subgroup) => {
            const regionKey = this.getRegionKeyFromSubgroup(subgroup);
            const all_highlighted_regions = [];
            this.state.announcements.map((announcement) => {
                return all_highlighted_regions.push(...announcement.regions)
            })

            const need_highlight = all_highlighted_regions.includes(regionKey)
            charts.push((
                <div
                    key={subgroup.key}
                    className={`chart-container`}
                    style={_.get(this.state.displayRegions, regionKey, false) ? {} : {display: 'none'}}
                >
                    <Paper className={`chart-container__paper ${need_highlight? 'chart-container__paper--highlighted': ''}`}>
                        <div id={'chart' + subgroup} className={'chart'} ref={(dom) => {
                            if (dom) {
                                let chart = echarts.init(dom);
                                this['charts'].push(chart);
                            }
                        }}/>
                        {this.makeAdditionalReportElement(subgroup)}
                    </Paper>
                </div>));
        });
        return (
            <React.Fragment>
                {margin}
                <div className={'realtime-report__body'}>
                    {charts}
                </div>
            </React.Fragment>
        );
    }

    makeAdditionalReportElement(subgroup) {
        return (
            <React.Fragment>
                <div className={'paper__metric-breakdowns'}>
                    {this.getBreakdownItems(subgroup)}
                </div>
            </React.Fragment>
        )
    }

    renderBreakDown(path, label, layers, valueProps){
        return (<Button
            className={'metric-breakdowns__link font--normal'}
            component={(itemProps) => this.renderNestedLink(path, layers, valueProps)(itemProps)}
        >
            {label} >
        </Button>)
    }
    makeLabel(appLabel, reportLabel) {
        return `${appLabel} Realtime ${super.makeLabel(appLabel, reportLabel)}`;
    }

    makeDatepicker() {
        return (
            <RealtimeDatepicker dates={this.state.dates} onDateChange={(i, date) => this.onDateChange(i, date)}
                                handleClick={() => this.handleDateSubmit()}/>
        );
    }

}

export {RealtimeReport};