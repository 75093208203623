import React, {Component} from 'react';
import _ from 'lodash';
import {Properties} from "../../common/properties";
import {UserMetaUtils} from "../../common/utils";
import {Section} from "../../components/SidebarSection";


class ShopeeSidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
        };
    }

    hasMetric(product, metric, frequencyBit) {
        return Boolean(this.props.userMeta.meta_data[product] &&
            UserMetaUtils.getMetaMetricKey(metric, frequencyBit) in this.props.userMeta.meta_data[product].metrics);
    }

    makeLinks(shopeeConfig) {
        return _.filter([
            {config: shopeeConfig, metric: 'ccu', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime CCU', metricGroup: "User"},
            {config: shopeeConfig, metric: 'ccu', frequencyBit: Properties.frequencyBit.daily, label: 'PCU', metricGroup: "User"},
            {config: shopeeConfig, metric: 'new_install', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime New Install', metricGroup: "New"},
            {config: shopeeConfig, metric: 'new_install', frequencyBit: Properties.frequencyBit.daily, label: 'New Install', metricGroup: "New"},
            {config: shopeeConfig, metric: 'new', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime New Register', metricGroup: 'New'},
            {config: shopeeConfig, metric: 'new', frequencyBit: Properties.frequencyBit.daily, label: 'New Register', metricGroup: 'New'},
            {config: shopeeConfig, metric: 'new_buyer', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime New Buyer', metricGroup: "New"},
            {config: shopeeConfig, metric: 'new_buyer', frequencyBit: Properties.frequencyBit.daily, label: 'New Buyer', metricGroup: "New"},
            {config: shopeeConfig, metric: 'active', frequencyBit: Properties.frequencyBit.daily, label: 'Active User', metricGroup: "User"},
            {config: shopeeConfig, metric: 'txn_user', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Buyer', metricGroup: 'Buyer'},
            {config: shopeeConfig, metric: 'txn_user', frequencyBit: Properties.frequencyBit.daily, label: 'Buyer',  metricGroup: 'Buyer'},
            {config: shopeeConfig, metric: 'order', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Order'},
            {config: shopeeConfig, metric: 'order', frequencyBit: Properties.frequencyBit.daily, label: 'Order'},
            {config: shopeeConfig, metric: 'order_shop', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Order (EC Physical)'},
            {config: shopeeConfig, metric: 'order_shop', frequencyBit: Properties.frequencyBit.daily, label: 'Order (EC Physical)'},
            {config: shopeeConfig, metric: 'order_cb', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Order (Cross Border)'},
            {config: shopeeConfig, metric: 'order_cb', frequencyBit: Properties.frequencyBit.daily, label: 'Order (Cross Border)'},
            {config: shopeeConfig, metric: 'order_sbs', frequencyBit: Properties.frequencyBit.daily, label: 'Order (Service by Shopee)'},
            {config: shopeeConfig, metric: 'order_shopeefood', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Order (ShopeeFood)'},
            {config: shopeeConfig, metric: 'order_shopeefood', frequencyBit: Properties.frequencyBit.daily, label: 'Order (ShopeeFood)'},
            {config: shopeeConfig, metric: 'gmv', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime GMV'},
            {config: shopeeConfig, metric: 'gmv', frequencyBit: Properties.frequencyBit.daily, label: 'GMV'},
            {config: shopeeConfig, metric: 'gmv_shop', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime GMV (EC Physical)'},
            {config: shopeeConfig, metric: 'gmv_shop', frequencyBit: Properties.frequencyBit.daily, label: 'GMV (EC Physical)'},
            {config: shopeeConfig, metric: 'gmv_cb', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime GMV (Cross Border)'},
            {config: shopeeConfig, metric: 'gmv_cb', frequencyBit: Properties.frequencyBit.daily, label: 'GMV (Cross Border)'},
            {config: shopeeConfig, metric: 'gmv_sbs', frequencyBit: Properties.frequencyBit.daily, label: 'GMV (Service by Shopee)'},
            {config: shopeeConfig, metric: 'gmv_shopeefood', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime GMV (ShopeeFood)'},
            {config: shopeeConfig, metric: 'gmv_shopeefood', frequencyBit: Properties.frequencyBit.daily, label: 'GMV (ShopeeFood)'},

        ], ({config, metric, frequencyBit}) => this.hasMetric(config.product, metric, frequencyBit));
    }

    render() {
        let shopeeConfig;
        if (this.props.userMeta.data) {
            for (let i = 0; i < this.props.userMeta.data.length; i++) {
                let productConfig = this.props.userMeta.data[i];
                if (productConfig.product_group === 'shopee_api') {
                    shopeeConfig = productConfig;
                    break;
                }
            }
        }

        return (
            <React.Fragment>
                {shopeeConfig && <Section key={'section' + shopeeConfig.label}
                                          productType={'shopee'}
                                          links={this.makeLinks(shopeeConfig)}
                                          label={shopeeConfig.label}
                                          additionalData={this.props.additionalData}
                                 />}
            </React.Fragment>
        )
    }
}

export {ShopeeSidebar};