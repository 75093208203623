import _ from 'lodash';
import {GamesRevenueReport} from "./Games.RevenueReport";


class GamesRevenueLtzReport extends GamesRevenueReport {
    getMetrics() {
        return super.getMetrics().map(metric => `${metric}_ltz`);
    }

    updateKeysWithLtz(obj) {
        return _.reduce(obj, function(result, value, key) {
            result[`${key}_ltz`] = value;
            return result;
        }, {});
    }

    getMetricHeader() {
        return this.updateKeysWithLtz(super.getMetricHeader());
    }

    getMetricFormatter() {
        return this.updateKeysWithLtz(super.getMetricFormatter());
    }
}

export {GamesRevenueLtzReport};
