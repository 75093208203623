// 'Pseudo' class here just to increase selectivity to override default material UI instead of using !important
import React, {Component} from 'react';
import {GamesSidebar} from "./Games/Games.Sidebar";
import {AirpaySidebar} from "./Airpay/Airpay.Sidebar";
import {ShopeeSidebar} from "./Shopee/Shopee.Sidebar";
import {OchaSidebar} from "./Ocha/Ocha.Sidebar";
import {FoodySidebar} from "./Foody/Foody.Sidebar";
import {MoneySidebar} from "./Money/Money.Sidebar";
import {CreditSidebar} from "./Credit/Credit.Sidebar";
import { SPXSidebar } from './SPX/SPX.Sidebar';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMetricLink: window.location.pathname
        };
        this.changeActiveMetricLink = this.changeActiveMetricLink.bind(this);
    }

    // To ensure that the metric clicked is only once across all "grandchildren"
    // Function to change state of the grandparents is selected is passed to be triggered when a metric is clicked
    // After the metric is clicked, it will re render everything so that only the selected metric will be highlighted
    // To check it is selected or not, the grand children will need to compare its link with the active metric link
    // (See SidebarLink.jsx for the comparison)
    changeActiveMetricLink(link) {
        this.setState({activeMetricLink: link});
    }

    render() {
        let productList = [];

        if (this.props.userMeta.data) {
            productList = this.props.userMeta.data.map(x => x.product);
        }
        let isSingleProduct = productList.length === 1 || this.props.userMeta.client === 'foody';

        let childProps = {
            ...this.props,
            additionalData: {
                activeMetricLink: this.state.activeMetricLink,
                changeActiveMetricLink: this.changeActiveMetricLink,
                isSingleProduct: isSingleProduct
            }
        };
        return (
            <React.Fragment>
                <Drawer
                    variant={'permanent'}
                    anchor={'left'}

                    // This sidebar__root--single-product will be used for every child class css
                    // to differentiate multi-product and single-product (different css for both)

                    classes={
                        {
                            paper: 'sidebar__paper pseudo',
                            root: 'sidebar__root pseudo ' + (isSingleProduct ? 'sidebar__root--single-product' : '')
                        }
                    }
                >
                    <List disablePadding={true} dense={true}>
                        <ShopeeSidebar {...childProps}/>
                        <AirpaySidebar {...childProps}/>
                        <FoodySidebar {...childProps}/>
                        <MoneySidebar {...childProps}/>
                        <CreditSidebar {...childProps}/>
                        <OchaSidebar {...childProps}/>
                        <GamesSidebar {...childProps}/>
                        <SPXSidebar {...childProps}/>
                    </List>
                </Drawer>
            </React.Fragment>
        )
    }
}

export default Sidebar;