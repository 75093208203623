import React from 'react';
import Grid from '@material-ui/core/Grid';

function ErrorMessage(props) {
    return (
        <Grid item xs={12}>
            {props.error}
        </Grid>
    )
}

export {ErrorMessage};
