import React, {Component} from 'react';
import _ from 'lodash';
import {Properties} from "../../common/properties";
import {UserMetaUtils} from "../../common/utils";
import {Section} from "../../components/SidebarSection";
import {Config} from "./Foody.Config";
import find from 'lodash/find'


class FoodySidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
        };
    }

    hasMetricGroup(product, metricGroup, frequencyBit) {
        let metrics = Config.getMetricsFromMetricGroup(metricGroup);
        return metrics.some((metric) => this.hasMetric(product, metric, frequencyBit));
    }

    hasMetric(product, metric, frequencyBit) {
        return Boolean(this.props.userMeta.meta_data[product] &&
            UserMetaUtils.getMetaMetricKey(metric, frequencyBit) in this.props.userMeta.meta_data[product].metrics);
    }

    makeLinks(foodyConfig) {
        return _.filter([
            {config: foodyConfig, metric: 'ccu', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime CCU'},
            {config: foodyConfig, metric: 'ccu', frequencyBit: Properties.frequencyBit.daily, label: 'PCU'},
            {config: foodyConfig, metric: 'order_net', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Net Order'},
            {config: foodyConfig, metric: 'order_net', frequencyBit: Properties.frequencyBit.daily, label: 'Net Order'},
            {config: foodyConfig, metric: 'order_gross', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Gross Order'},
            {config: foodyConfig, metric: 'order_gross', frequencyBit: Properties.frequencyBit.daily, label: 'Gross Order'},
            {config: foodyConfig, metric: 'gmv_net', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Net GMV'},
            {config: foodyConfig, metric: 'gmv_net', frequencyBit: Properties.frequencyBit.daily, label: 'Net GMV'},
            {config: foodyConfig, metric: 'new_buyer', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime New Buyer'},
            {config: foodyConfig, metric: 'new_buyer', frequencyBit: Properties.frequencyBit.daily, label: 'New Buyer'},
            {config: foodyConfig, metric: 'txn_user', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Txn User'},
            {config: foodyConfig, metric: 'txn_user', frequencyBit: Properties.frequencyBit.daily, label: 'Txn User'},
        ], ({config, metric, frequencyBit}) => this.hasMetricGroup(config.product, metric, frequencyBit));
    }

    render() {
        const foodyConfig = this.props.userMeta.data && find(this.props.userMeta.data, config => config.product === 'shopeefood_web')
        return (
            <React.Fragment>
                {foodyConfig && <Section key={'section' + foodyConfig.label}
                                         productType={'shopeefood_web'}
                                         links={this.makeLinks(foodyConfig)}
                                         label={foodyConfig.label}
                                         additionalData={this.props.additionalData}
                                />}
            </React.Fragment>
        )
    }
}

export {FoodySidebar};