import React from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import _ from 'lodash';
import * as moment from "moment-timezone";
import {Utils} from "../common/utils";
import {Properties} from "../common/properties";
import {DailyDatepicker} from "../components/Datepicker";
import {DownloadButton} from "../components/DownloadButton";
import {Report} from "./Report"

import "../assets/css/DailyReport.css"
import {PopupMenu} from "../components/PopupMenu"
import MenuItem from "@material-ui/core/MenuItem";


class DailyReport extends Report {

    getEarliestDateConstant() {
        return moment('1970-01-01');
    }

    getConstructorStates() {
        // dataStartDate and dataEndDate are for the actual data display,
        // while startDate and endDate only for the date shown on the datepicker
        let startDate = this.getNow().subtract(7, 'days');
        let endDate = this.getNow().subtract(1, 'days');
        return {
            dataStartDate: startDate,
            dataEndDate: endDate,
            earliestDate: {},
            startDate: startDate,
            endDate: endDate,
            metricPeriods: {},
            ...super.getConstructorStates()
        };
    }

    getDates(earliestDate = this.getEarliestDateConstant()) {
        let date = moment(Math.max(this.state.dataStartDate, earliestDate));
        let endDate = moment(Math.min(this.state.dataEndDate, this.getNow()));
        let dates = [];
        while (date.isSameOrBefore(endDate, 'day')) {
            dates.push(Utils.formatMomentForApi(date, Properties.frequencyBit.daily));
            date.add(1, 'days');
        }

        return dates;
    }

    getEarliestDate(regionKey = '') {
        return _.min(Object.values(this.state.earliestDate).concat(this.getNow()));
    }

    getInitialLoadingState() {
        return {
            dataStartDate: this.state.startDate,
            dataEndDate: this.state.endDate,
            ...super.getInitialLoadingState()
        };
    }

    getReportSpecificApiParams() {
        let params = super.getReportSpecificApiParams();
        return {
            start_date: Utils.formatMomentForApi(this.state.startDate, this.getFrequencyBit()),
            end_date: Utils.formatMomentForApi(this.state.endDate, this.getFrequencyBit()),
            ...params,
        }
    }

    getFrequencyBit() {
        return Properties.frequencyBit.daily;
    }

    updateDataDictFromData(data, metric, dateMetaData, subgroup, period) {
        let updateDataDict = {};
        data.forEach(datum => {
            let dataKey = this.makeDataDictKey(metric, this.getDataKey(), datum.date, subgroup, period);
            updateDataDict[dataKey] = datum.value;
        });
        return updateDataDict;
    }

    updateOtherStatesFromResponse(response, needToUpdateInitialState) {
        super.updateOtherStatesFromResponse(response, needToUpdateInitialState);
        let earliestDateDict = this.state.earliestDate;
        response.data.forEach(groupData => {
            let metaData = _.get(groupData, 'meta_data', {});
            let regionKey = _.get(metaData, 'data_key');
            earliestDateDict[regionKey] = moment(_.get(metaData, 'earliest_date', this.getEarliestDateConstant()));
        });
        this.setState({earliestDate: earliestDateDict});
    }

    handleDateChange(stateKey, date) {
        let state = {};
        state[stateKey] = date;
        this.setState(state);
    }

    makeDownloadProps() {
        let startDate = Utils.formatMomentForApi(this.state.dataStartDate, this.getFrequencyBit()),
            endDate = Utils.formatMomentForApi(this.state.dataEndDate, this.getFrequencyBit()),
            downloadFileName = `${this.makeCsvLabel(this.props.productConfig.label, this.props.reportLabel)} - ` +
                `${startDate}_` +
                `${endDate}.csv`;
        return {
            downloadLink: this.getDownloadLink(),
            downloadFileName: downloadFileName,
            frequencyBit: Properties.frequencyBit.daily,
            product: this.props.productConfig.product,
            downloadDetails: {
                region: this.getFilteredRegions().map(region => region.label),
                start_date: startDate,
                end_date: endDate,
                report: this.props.reportLabel,
            },
            onDownloadFail: (message) => this.setState({
                errorMessage: message
            }),
            onDownloadStart: () => this.setState({errorMessage: ''}),
        }
    }

    makeCsvLabel(appLabel, reportLabel) {
        return this.makeLabel(appLabel, reportLabel);
    }

    getFilteredRegions() {
        return this.state.regions.filter(region => this.state.displayRegions[region.key]);
    }

    getDownloadLink() {
        return Utils.createCsv(this.getCsvRowsContents());
    }

    getCsvRowsContents() {
        let tableData = this.getTableData();
        return this.makeCsvRowsFromTableData(tableData);
    }

    getTableData() {
        let headerData = this.getSubgroups()
            .map(subgroup => this.getMetricBreakdownLink(subgroup))
            .filter(data => data.content !== null);

        headerData.unshift({key: 'date_title', content: 'DATE', className: this.getHeaderCellClassName()});
        let contentData = this.getContentData();
        return {
            header: headerData,
            content: contentData,
        };
    }

    renderBreakDown(path, label, layers, valueProps) {
        return <MenuItem key={Math.random()}
                         className={'link-group__dropdown-menu'}
                         component={(itemProps) => (
                             this.renderNestedLink(path, layers, valueProps)(itemProps))}
                         onClick={this.handleClose}>{label}</MenuItem>
    }

    getMetricBreakdownLink(subgroup) {
        const breakdownItems = this.getBreakdownItems(subgroup);
        const regionKey = this.getRegionKeyFromSubgroup(subgroup);
        return {
            key: `${Math.random()} ${regionKey}`,
            content: this.state.displayRegions[regionKey] ? subgroup.label : null,
            className: this.getHeaderCellClassName(),
            contentLink: (
                <React.Fragment>
                    <div className={'header__link-group'}>
                        {Object.entries(breakdownItems).length !== 0 && (
                            <PopupMenu>
                                {breakdownItems}
                            </PopupMenu>)}
                    </div>
                </React.Fragment>
            )
        }
    }

    getHeaderCellClassName() {
        return 'font--semi-bold header__cell';
    }

    getNormalCellClassName() {
        return 'font--normal';
    }

    getContentData(period = 1) {
        let contentRows = [];
        let metric = this.getMetrics()[0];
        let earliestDate = this.getEarliestDate();
        this.getDates(earliestDate).forEach(date => {
            let row = [{content: date, className: this.getNormalCellClassName()}];

            this.getSubgroups().forEach(subgroup => {
                let regionKey = this.getRegionKeyFromSubgroup(subgroup);
                if (!this.state.displayRegions[regionKey]) {
                    return;
                }

                let dataKey = this.makeDataDictKey(metric, this.getDataKey(), date, subgroup.key, period);
                let content = _.get(this.state.dataDict, dataKey, '');
                if (content !== '') {
                    let sign = content >= 0 ? 1 : -1
                    const valueProps = this.getValueProps()
                    const dp = _.get(valueProps, 'decimal_point', 0)
                    let prefix = _.get(valueProps, 'prefix', '');
                    let suffix = _.get(valueProps, 'suffix', '');
                    content = Utils.formatCommaSeparatedNumber(content, dp)
                    // prefix(e.g $) should be after the negative sign, i.e -$1, not $-1
                    content = prefix + _.replace(content, '-', '') + suffix
                    content = sign === -1? '-' + content: content
                }

                row.push({
                    content: content,
                    className: this.getNormalCellClassName(),
                    key: `${date}, ${subgroup.key}, ${period}, ${content} ${Math.random()}`
                });
            });
            contentRows.push(row);
        });
        return contentRows;
    }

    makeCsvRowsFromTableData(tableData) {
        let headerRow = _.get(tableData, 'header', []);
        let secondHeaderRow = _.get(tableData, 'secondHeader', []);
        let content = _.get(tableData, 'content', []);

        return [headerRow, secondHeaderRow, ...content]
            .filter(row => row.length)
            .map(row => row.map(cell => _.get(cell, 'content', '')));
    }

    makeReport() {
        let tableData = this.getTableData();
        return this.renderTable(tableData);
    }

    renderTable(tableData, key = 'everything') {
        return (
            <Grid item className={'daily-report__table table'} key={key}>
                <Paper className={'paper table__paper'}>
                    <Table padding={'dense'}>
                        <TableHead key={'header'} className={'table__header header'}>
                            {this.renderRow(tableData.header, this.getHeaderRowClassName())}
                            {this.renderRow(tableData.secondHeader, this.getHeaderRowClassName())}
                        </TableHead>
                        <TableBody>
                            {tableData.content.map(content => this.renderRow(content, this.getNormalRowClassName()))}
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        )
    }

    getHeaderRowClassName() {
        return 'header__row';
    }

    getNormalRowClassName() {
        return 'table__row';
    }

    renderRow(rowData, className = '') {
        return rowData ? (
            <TableRow key={Math.random().toString()} className={className}>
                {rowData.map(cellData => this.renderCell(cellData))}
            </TableRow>
        ) : (
            <React.Fragment/>
        )
    }

    renderCell(cellData) {
        cellData = this.makeDefaultCellValues(cellData);
        // For cell with colspan > 1 or rowspan > 1, the 'absorbed' cell should have isHidden = true --> only shown in csv
        // (in csv there is no rowspan and colspan)
        return cellData.isHidden ? (
            <React.Fragment key={Math.random().toString()}/>
        ) : (
            <TableCell key={cellData.key} className={`table__cell ${cellData.className}`} rowSpan={cellData.rowSpan}
                       colSpan={cellData.colSpan} align={cellData.align}
            >
                {cellData.content}
            </TableCell>
        )
    }

    makeDefaultCellValues(cellData) {
        let defaultCellValues = {
            key: _.get(cellData, 'key', '' + Object.values(cellData)),
            content: _.get(cellData, 'content', '').toString(),
            rowSpan: _.get(cellData, 'rowSpan', 1),
            colSpan: _.get(cellData, 'colSpan', 1),
            className: _.get(cellData, 'className', ''),
            align: _.get(cellData, 'align', 'inherit'),
            isHidden: _.get(cellData, 'isHidden', false),
        };
        if (cellData.contentLink !== undefined) {
            defaultCellValues.content = (
              <div className="flex">
                {defaultCellValues.content}
                {cellData.contentLink}
              </div>
            )
        } else {
          defaultCellValues.className = defaultCellValues.className + ' cell__padded';
        }
        return defaultCellValues;
    }

    makeLabel(appLabel, reportLabel) {
        return `${appLabel} ${super.makeLabel(appLabel, reportLabel)} - History`;
    }

    makeDatepicker() {
        return (
            <DailyDatepicker startDate={this.state.startDate} endDate={this.state.endDate}
                             handleDateChange={(stateKey, date) => this.handleDateChange(stateKey, date)}
                             handleClick={() => this.handleDateSubmit()}
            />
        );
    }

    makeDownloadButton() {
        return (
            <DownloadButton {...this.makeDownloadProps()}/>
        );
    }
}

export {DailyReport};