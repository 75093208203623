import React, {Component} from 'react';
import _ from 'lodash';
import {Properties} from "../../common/properties";
import {UserMetaUtils} from "../../common/utils";
import {Section} from "../../components/SidebarSection";


class OchaSidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
        };
    }

    hasMetric(product, metric, frequencyBit) {
        return Boolean(this.props.userMeta.meta_data[product] &&
            UserMetaUtils.getMetaMetricKey(metric, frequencyBit) in this.props.userMeta.meta_data[product].metrics);
    }

    makeLinks(ochaConfig) {
        return _.filter([
            {config: ochaConfig, metric: 'order', frequencyBit: Properties.frequencyBit.daily, label: 'Order'},
            {config: ochaConfig, metric: 'gmv', frequencyBit: Properties.frequencyBit.daily, label: 'GMV'},
            {config: ochaConfig, metric: 'active_shop', frequencyBit: Properties.frequencyBit.daily, label: 'Active Shop'},
            {config: ochaConfig, metric: 'paying_shop', frequencyBit: Properties.frequencyBit.daily, label: 'Paying Shop'},

        ], ({config, metric, frequencyBit}) => this.hasMetric(config.product, metric, frequencyBit));
    }

    render() {
        let ochaConfig;
        if (this.props.userMeta.data) {
            for (let i = 0; i < this.props.userMeta.data.length; i++) {
                let productConfig = this.props.userMeta.data[i];
                if (productConfig.product_group === 'ocha_api') {
                    ochaConfig = productConfig;
                    break;
                }
            }
        }

        return (
            <React.Fragment>
                {ochaConfig && <Section key={'section' + ochaConfig.label}
                                        productType={'ocha'}
                                        links={this.makeLinks(ochaConfig)}
                                        label={ochaConfig.label}
                                        additionalData={this.props.additionalData}
                               />}
            </React.Fragment>
        )
    }
}

export {OchaSidebar};