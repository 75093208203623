const ApiPath = {
    dashboard: '/api/dashboard/',
    home: '/',
    login: '/auth/login/seaauth-oauth2/',
    logout: '/api/logout/',
    meta: '/api/meta/',
};

const Properties = {
    apiCookieName: 'csrftoken',
    frequencyBit: {
        realtime: 1,
        daily: 2,
        monthly: 4,
        dateRange: 8,
    },
    frequencyMap: {
        1: 'realtime',
        2: 'daily',
        4: 'monthly',
        8: 'date-range',
    },
    frequencyDateFormat: {
        1: 'YYYY-MM-DD',
        2: 'YYYY-MM-DD',
        4: 'YYYY-MM',
        8: 'YYYY-MM-DD',
    }
};

export {ApiPath, Properties};