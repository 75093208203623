import {AbstractRouter, RouterConfig} from "../Router";
import {FoodyDailyReport} from "./Foody.DailyReport";

import {FoodyRealtimeReport} from "./Foody.RealtimeReport";
import {Properties} from "../../common/properties";
import {Config} from "./Foody.Config";



class FoodyRouterConfig extends RouterConfig {
    constructor(configDict, matchUrl) {
        super(configDict, matchUrl);
        this.configMetrics = Config.getMetricsFromMetricGroup(this.metric);
    }

    doesMetaIncludeMetric(metric, metaConfig) {
        return this.configMetrics.some((configMetric) => super.doesMetaIncludeMetric(configMetric, metaConfig));
    }

    getMetricKey(metricConfig) {
        let metricKey = super.getMetricKey(metricConfig);
        return metricKey.replace(/_total|_shopee|_food|_ship|_market/g, '');
    }
}


class FoodyRouter extends AbstractRouter {

    getConfigDicts() {
        let realtimeConfigDicts = this.getRealtimeConfigDicts();
        let dailyConfigDicts = this.getDailyConfigDicts();
        return [
            ...realtimeConfigDicts,
            ...dailyConfigDicts,
        ];
    }

    getRealtimeConfigDicts() {
        let realtimeConfigDicts = [
            {metric: 'order_gross', reportLabel: 'Gross Order', reportClass: FoodyRealtimeReport},
            {metric: 'order_gross_by_city', reportLabel: 'By Area', reportClass: FoodyRealtimeReport},
            {metric: 'order_net', reportLabel: 'Net Order', reportClass: FoodyRealtimeReport},
            {metric: 'order_net_by_city', reportLabel: 'By Area', reportClass: FoodyRealtimeReport},
            {
                metric: 'gmv_net', reportLabel: 'Net GMV',
                reportClass: FoodyRealtimeReport,
            },
            {
                metric: 'gmv_net_by_city', reportLabel: 'By Area',
                reportClass: FoodyRealtimeReport,
            },
            {metric: 'new_buyer', reportLabel: 'New Buyer', reportClass: FoodyRealtimeReport},
            {metric: 'new_buyer_by_city', reportLabel: 'By Area', reportClass: FoodyRealtimeReport},
            {metric: 'txn_user', reportLabel: 'Txn User', reportClass: FoodyRealtimeReport},
            {metric: 'txn_user_by_city', reportLabel: 'By Area', reportClass: FoodyRealtimeReport},
            {metric: 'ccu', reportLabel: 'CCU', reportClass: FoodyRealtimeReport},
        ];
        return realtimeConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.realtime,
                productGroup: 'foody_api',
                ...configDict
            }
        });
    }

    getDailyConfigDicts() {
        let dailyConfigDicts = [
            {metric: 'order_gross', reportLabel: 'Gross Order', reportClass: FoodyDailyReport},
            {metric: 'order_gross_by_city', reportLabel: 'By Area', reportClass: FoodyDailyReport},
            {metric: 'order_net', reportLabel: 'Net Order', reportClass: FoodyDailyReport},
            {metric: 'order_net_by_city', reportLabel: 'By Area', reportClass: FoodyDailyReport},
            {
                metric: 'gmv_net', reportLabel: 'Net GMV', reportClass: FoodyDailyReport,
            },
            {
                metric: 'gmv_net_by_city', reportLabel: 'By Area', reportClass: FoodyDailyReport,
            },
            {metric: 'new_buyer', reportLabel: 'New Buyer', reportClass: FoodyDailyReport},
            {metric: 'new_buyer_by_city', reportLabel: 'By Area', reportClass: FoodyDailyReport},
            {metric: 'txn_user', reportLabel: 'Txn User', reportClass: FoodyDailyReport},
            {metric: 'txn_user_by_city', reportLabel: 'By Area', reportClass: FoodyDailyReport},
            {metric: 'ccu', reportLabel: 'PCU'},
        ];
        return dailyConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: FoodyDailyReport,
                productGroup: 'foody_api',
                ...configDict
            }
        });
    }

    getMatchUrl() {
        return '/shopeefood_web';
    }

    createRouterConfig(configDict, matchUrl) {
        return new FoodyRouterConfig(configDict, matchUrl);
    }
}

export {FoodyRouter};