import {AbstractRouter} from "../../Router";
import {DailyReport} from "../../DailyReport";
import {Properties} from "../../../common/properties";


class BliphRouter extends AbstractRouter {

    getConfigDicts() {
        let dailyBalanceConfigDicts = this.getDailyBalanceConfigDicts();
        let dailyloanConfigDicts = this.getLoanConfigDicts();
        let dailyTreasureConfigDicts = this.getDailyTreasureConfigDicts();
        return [
            ...dailyBalanceConfigDicts,
            ...dailyloanConfigDicts,
            ...dailyTreasureConfigDicts,
        ];
    }

    getDailyBalanceConfigDicts() {
        let dailyConfigDicts = [
            {
                metric: 'balance', reportLabel: 'Balance',
            },
        ];
        return dailyConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
                ...configDict
            }
        });
    }

    getLoanConfigDicts() {
        return [


            {
                metric: 'loan',
                reportLabel: 'Loan',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },

        ];
    }

    getDailyTreasureConfigDicts() {
        let dailyConfigDicts = [
            {
                metric: 'treasure', reportLabel: 'Treasure',
            },
        ];
        return dailyConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
                ...configDict
            }
        });
    }

    getMatchUrl() {
        return '/bliph';
    }
}

export {BliphRouter};