import {AbstractRouter} from "../../Router";
import {DailyReport} from "../../DailyReport";
import {Properties} from "../../../common/properties";
import {RealtimeReport} from "../../RealtimeReport";
import {RealtimeCcuReport} from "./SeaBank.RealtimeCcuReport";


class SeaBankRouter extends AbstractRouter {

    getConfigDicts() {
        let txnConfigDicts = this.getTxnConfigDicts();
        let tpvConfigDicts = this.getTpvConfigDicts();
        let txnInVsOutConfigDicts = this.getTxnInVsOutConfigDicts();
        let tpvInVsOutConfigDicts = this.getTpvInVsOutConfigDicts();
        let txnInConfigDicts = this.getTxnInConfigDicts();
        let tpvInConfigDicts = this.getTpvInConfigDicts();
        let txnOutConfigDicts = this.getTxnOutConfigDicts();
        let tpvOutConfigDicts = this.getTpvOutConfigDicts();
        let NewCustomerConfigDicts = this.getNewCustomerConfigDicts();
        let NewRegisterConfigDicts = this.getNewRegisterConfigDicts();
        let DepositConfigDicts = this.getDepositConfigDicts();
        let DepositChangeConfigDicts = this.getDepositChangeConfigDicts();
        let CcuConfigDicts = this.getCcuConfigDicts();
        let NewTxnUserDicts = this.getNewTxnUserConfigDicts();
        let TxnUserDicts = this.getTxnUserConfigDicts();
        let ActiveUserDicts = this.getActiveUserConfigDicts();
        let loanConfigDicts = this.getLoanConfigDicts();
        let borrowerDicts = this.getBorrowerConfigDicts();
        let investmentDicts = this.getInvestmentConfigDicts();
        let cardDicts = this.getCardConfigDicts();

        return [
            ...txnConfigDicts,
            ...tpvConfigDicts,
            ...txnInVsOutConfigDicts,
            ...tpvInVsOutConfigDicts,
            ...txnInConfigDicts,
            ...tpvInConfigDicts,
            ...txnOutConfigDicts,
            ...tpvOutConfigDicts,
            ...NewCustomerConfigDicts,
            ...NewRegisterConfigDicts,
            ...DepositConfigDicts,
            ...DepositChangeConfigDicts,
            ...CcuConfigDicts,
            ...NewTxnUserDicts,
            ...TxnUserDicts,
            ...ActiveUserDicts,
            ...loanConfigDicts,
            ...borrowerDicts,
            ...investmentDicts,
            ...cardDicts,
        ];
    }

    getTxnConfigDicts() {
        return [
            {
                metric: 'txn',
                reportLabel: 'Txn',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },

            {
                metric: 'txn',
                reportLabel: 'Txn',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
        ]
    }

    getTpvConfigDicts() {
        return [
            {
                metric: 'tpv',
                reportLabel: 'TPV',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'tpv',
                reportLabel: 'TPV',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },

        ]
    }

    getTxnInVsOutConfigDicts() {
        return [
            {
                metric: 'txn_in_vs_out',
                reportLabel: 'In & Out',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },

            {
                metric: 'txn_in_vs_out',
                reportLabel: 'In & Out',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
        ]
    }

    getTpvInVsOutConfigDicts() {
        return [
            {
                metric: 'tpv_in_vs_out',
                reportLabel: 'In & Out',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'tpv_in_vs_out',
                reportLabel: 'In & Out',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },

        ]
    }

    getTxnInConfigDicts() {
        return [
            {
                metric: 'txn_in',
                reportLabel: 'Transfer From',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },

            {
                metric: 'txn_in',
                reportLabel: 'Transfer From',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
        ]
    }

    getTpvInConfigDicts() {
        return [
            {
                metric: 'tpv_in',
                reportLabel: 'Transfer From',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'tpv_in',
                reportLabel: 'Transfer From',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },

        ]
    }
    getTxnOutConfigDicts() {
        return [
            {
                metric: 'txn_out',
                reportLabel: 'Transfer To',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },

            {
                metric: 'txn_out',
                reportLabel: 'Transfer To',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
        ]
    }

    getTpvOutConfigDicts() {
        return [
            {
                metric: 'tpv_out',
                reportLabel: 'Transfer To',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'tpv_out',
                reportLabel: 'Transfer To',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },

        ]
    }
    getNewCustomerConfigDicts() {
        return [
            {
                metric: 'new_customer',
                reportLabel: 'New Customer',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },

            {
                metric: 'new_customer',
                reportLabel: 'New Customer',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },

            {
                metric: 'new_customer_by_type',
                reportLabel: 'User Type',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },

            {
                metric: 'new_customer_by_type',
                reportLabel: 'User Type',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            }
        ]
    }
    getNewRegisterConfigDicts() {
        return [
            {
                metric: 'new_register',
                reportLabel: 'New Register',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },

            {
                metric: 'new_register',
                reportLabel: 'New Register',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },

            {
                metric: 'new_register_by_type',
                reportLabel: 'User Type',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },

            {
                metric: 'new_register_by_type',
                reportLabel: 'User Type',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            }
        ]
    }

    getDepositConfigDicts() {
        return [
            {
                metric: 'deposit',
                reportLabel: 'Deposit',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'deposit',
                reportLabel: 'Deposit',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'deposit_by_type',
                reportLabel: 'Sav & TD',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'deposit_by_type',
                reportLabel: 'Sav & TD',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
        ]
    }

    getDepositChangeConfigDicts() {
        return [
            {
                metric: 'deposit_change',
                reportLabel: 'Deposit Change',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'deposit_change',
                reportLabel: 'Deposit Change',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'deposit_change_by_type',
                reportLabel: 'Sav & TD',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'deposit_change_by_type',
                reportLabel: 'Sav & TD',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
        ]
    }

    getCcuConfigDicts() {
        return [
            {
                metric: 'ccu',
                reportLabel: 'CCU',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeCcuReport,
                productGroup: 'money_api',
            },
        ]
    }

    getNewTxnUserConfigDicts() {
        return [
            {
                metric: 'new_txn_user',
                reportLabel: 'New Txn User',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
        ]
    }
    getTxnUserConfigDicts() {
        return [
            {
                metric: 'txn_user',
                reportLabel: 'Txn User',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
        ]
    }
    getActiveUserConfigDicts() {
        return [
            {
                metric: 'active',
                reportLabel: 'DAU',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
        ]
    }

    getLoanConfigDicts() {
        return [
            {
                metric: 'loan_outstanding',
                reportLabel: 'Loan Outstanding',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_outstanding',
                reportLabel: 'Loan Outstanding',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_outstanding_change',
                reportLabel: 'Loan Outstanding Change',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_outstanding_change',
                reportLabel: 'Loan Outstanding Change',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_disbursement',
                reportLabel: 'Loan Disbursement',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_disbursement',
                reportLabel: 'Loan Disbursement',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_repayment',
                reportLabel: 'Loan Repayment',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_repayment',
                reportLabel: 'Loan Repayment',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_outstanding_by_type',
                reportLabel: 'Loan Type',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_outstanding_by_type',
                reportLabel: 'Loan Type',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_outstanding_retail_by_type',
                reportLabel: 'Interest-free vs Revolving',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_outstanding_retail_by_type',
                reportLabel: 'Interest-free vs Revolving',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_outstanding_change_by_type',
                reportLabel: 'Loan Type',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_outstanding_change_by_type',
                reportLabel: 'Loan Type',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_outstanding_change_retail_by_type',
                reportLabel: 'Interest-free vs Revolving',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_outstanding_change_retail_by_type',
                reportLabel: 'Interest-free vs Revolving',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_disbursement_by_type',
                reportLabel: 'Loan Type',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_disbursement_by_type',
                reportLabel: 'Loan Type',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_repayment_by_type',
                reportLabel: 'Loan Type',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_repayment_by_type',
                reportLabel: 'Loan Type',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
        ]
    }

    getBorrowerConfigDicts() {
        return [
            {
                metric: 'cumulative_borrower',
                reportLabel: 'Cumulative Borrowers',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'cumulative_borrower',
                reportLabel: 'Cumulative Borrowers',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'new_borrower',
                reportLabel: 'New Borrowers',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'new_borrower',
                reportLabel: 'New Borrowers',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'outstanding_borrower',
                reportLabel: 'Outstanding Borrowers',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'outstanding_borrower',
                reportLabel: 'Outstanding Borrowers',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },            
            {
                metric: 'cumulative_borrower_by_type',
                reportLabel: 'Loan Type',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'cumulative_borrower_by_type',
                reportLabel: 'Loan Type',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'new_borrower_by_type',
                reportLabel: 'Loan Type',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'new_borrower_by_type',
                reportLabel: 'Loan Type',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'outstanding_borrower_by_type',
                reportLabel: 'Loan Type',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'outstanding_borrower_by_type',
                reportLabel: 'Loan Type',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'outstanding_borrower_retail_by_type',
                reportLabel: 'Interest-free vs Revolving',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'outstanding_borrower_retail_by_type',
                reportLabel: 'Interest-free vs Revolving',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
        ]
    }

    getInvestmentConfigDicts() {
        return [
            {
                metric: 'investment_aua',
                reportLabel: 'Investment AUA',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'investment_aua',
                reportLabel: 'Investment AUA',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'investment_aua_change',
                reportLabel: 'AUA Change',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'investment_aua_change',
                reportLabel: 'AUA Change',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
        ]
    }

    getCardConfigDicts() {
        return [
            {
                metric: 'card_txn',
                reportLabel: 'Card Txn',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'card_txn',
                reportLabel: 'Card Txn',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'card_tpv',
                reportLabel: 'Card TPV',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'card_tpv',
                reportLabel: 'Card TPV',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'card_outstanding_balance',
                reportLabel: 'Card Outstanding Balance',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'card_outstanding_balance',
                reportLabel: 'Card Outstanding Balance',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
        ]
    }


    getMatchUrl() {
        return '/seabank';
    }
}

export {SeaBankRouter};