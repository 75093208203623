import React from 'react';
import Menu from '@material-ui/core/Menu';

import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import "../assets/css/DailyReport.css"

class PopupMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        };
    }

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };


    render() {
        return (
            <div>
                <IconButton aria-label="delete" aria-haspopup="true" onClick={this.handleClick}>
                    <ChevronRightIcon/>
                </IconButton>
                {this.props.value}
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    {this.props.children}
                </Menu>
            </div>
        );
    }
}

export {PopupMenu}
