import {RealtimeReport} from "../RealtimeReport";
import _ from "lodash";

class GamesRealtimeTopupReport extends RealtimeReport {

    getConstructorStates() {
        return {
            providers: [],
            providerRegionMap: {},
            ...super.getConstructorStates()
        }
    }

    getSubgroupFromGroupDataMeta(metaData) {
        return metaData.provider_id.toString();
    }

    updateOtherStatesFromResponse(response, needToUpdateInitialState) {
        if (!needToUpdateInitialState) {
            return;
        }

        let providers = [];
        let providerKeysSet = new Set();
        let providerRegionMap = {};
        response.data.forEach(groupData => {
            let metaData = _.get(groupData, 'meta_data', {});
            let providerId = metaData.provider_id.toString();
            let providerLabel = metaData.provider_label;
            let regionKey = metaData.region;

            if (!providerKeysSet.has(providerId)) {
                providers.push({key: providerId, label: providerLabel});
                providerKeysSet.add(providerId);
                providerRegionMap[providerId] = regionKey;
            }
        });
        providers.sort();
        this.setState({
            providers: providers,
            providerRegionMap: providerRegionMap
        });
    }

    getSubgroups() {
        return this.state.providers;
    }

    getRegionKeyFromSubgroup(subgroup) {
        return this.state.providerRegionMap[subgroup.key];
    }
}

export {GamesRealtimeTopupReport};