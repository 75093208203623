import _ from 'lodash';
import {Utils} from "../../common/utils";
import {MultiMetricsDailyReport} from "../MultiMetricsDailyReport";

const METRICS = ['active', 'new', 'retention', 'churn', 'revival'];
const METRIC_PARAMS = {
    'active': {period: [1, 7, 14, 30]},
};
const METRIC_FORMATTER = {
    'retention': val => Utils.adaptiveRound(val * 100) + '%',
    'churn': val => Utils.adaptiveRound(val * 100) + '%',
};
const METRIC_HEADER = {
    active: {
        headerLabel: 'Active User',
        secondHeaderPrefix: 'A'
    },
    new: {
        headerLabel: 'New User & Retention',
    },
    retention: {
        headerLabel: '',
        secondHeaderPrefix: 'R'
    },
    churn: {
        headerLabel: 'Churn User',
        secondHeaderPrefix: 'C'
    },
    revival: {
        headerLabel: 'Revival',
        secondHeaderSuffix: 'D'
    },
};


class GamesUserReport extends MultiMetricsDailyReport {
    getMetrics() {
        return METRICS;
    }

    getMetricSpecificApiParams(metric) {
        let params = super.getMetricSpecificApiParams(metric);
        return {
            ...params,
            ..._.get(METRIC_PARAMS, metric, {}),
        };
    }

    getMetricHeader() {
        return METRIC_HEADER;
    }

    getMetricFormatter() {
        return METRIC_FORMATTER;
    }

    getTableMetricColSpan(metric, region) {
        if (_.includes(metric, 'retention')) {
            return 0;
        } else if (_.includes(metric, 'new')) {
            return super.getTableMetricColSpan(metric, region) + super.getTableMetricColSpan(metric.replace('new', 'retention'), region);
        }
        return super.getTableMetricColSpan(metric, region);
    }

    needDoubleRowSpan(region, metric) {
        return false;
    }

    needLeftBorder(metric, region, period) {
        if (_.includes(metric, 'retention')) {
            return false;
        }
        return super.needLeftBorder(metric, region, period);
    }

    getTableMetricSecondHeader(metric, period) {
        if (_.includes(metric, 'new')) {
            return 'New';
        }
        return super.getTableMetricSecondHeader(metric, period);
    }
}

export {GamesUserReport};