import {AbstractRouter} from "../../Router";
import {RealtimeReport} from '../../RealtimeReport';
import {DailyReport} from "../../DailyReport";
import {Properties} from "../../../common/properties";



class BkeidRouter extends AbstractRouter {

    getConfigDicts() {
        let realtimeConfigDicts = this.getRealtimeBalanceConfigDicts();
        let dailyConfigDicts = this.getDailyBalanceConfigDicts();
        let loanConfigDicts = this.getLoanConfigDicts();
        let dailyTreasureConfigDicts = this.getDailyTreasureConfigDicts();
        return [
            ...realtimeConfigDicts,
            ...dailyConfigDicts,
            ...loanConfigDicts,
            ...dailyTreasureConfigDicts,
        ];
    }

    getRealtimeBalanceConfigDicts() {
        let realtimeConfigDicts = [
            {
                metric: 'balance', reportLabel: 'Balance',
            },
        ];
        return realtimeConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
                ...configDict
            }
        });
    }

    getDailyBalanceConfigDicts() {
        let dailyConfigDicts = [
            {
                metric: 'balance', reportLabel: 'Balance',
            },
        ];
        return dailyConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
                ...configDict
            }
        });
    }

    getLoanConfigDicts() {
        return [
            {
                metric: 'loan',
                reportLabel: 'Loan',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_by_type',
                reportLabel: 'By type',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan',
                reportLabel: 'Loan',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
            {
                metric: 'loan_by_type',
                reportLabel: 'By Type',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
            },
        ];
    }

    getDailyTreasureConfigDicts() {
        let dailyConfigDicts = [
            {
                metric: 'treasure', reportLabel: 'Treasure',
            },
        ];
        return dailyConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'money_api',
                ...configDict
            }
        });
    }

    getMatchUrl() {
        return '/bkeid';
    }
}

export {BkeidRouter};