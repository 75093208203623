// 'Pseudo' class here just to increase selectivity to override default material UI instead of using !important
import React, {Component, Fragment} from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {SidebarLink} from "./SidebarLink";
import {Utils} from "../common/utils";

class Section extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.isThisOpen(),
        };
        this.setHover = this.setHover.bind(this);
    }

    isThisOpen() {
        let isOpen = false;
        this.props.links.forEach(link => {
            let linkUrl = this.generateLinkUrl(link);
            if (linkUrl === this.props.additionalData.activeMetricLink) {
                isOpen = true;
            }
        });
        return isOpen;
    }

    generateLinkUrl(link) {
        return Utils.getUrlPath({
            matchUrl: `/${this.props.productType}`,
            metric: link.metric,
            product: link.config.product,
            frequencyBit: link.frequencyBit
        });
    }

    // To render metrics (and inserting metric group title between groups)
    renderMetrics(linkData) {
        let metricGroupMapping = {
            'ccu': 'CCU',
            'ccu_total_food': 'CCU',
            'ccu_shopee_food': 'CCU',
            'user_ccu': 'CCU',
            'streamer_ccu': 'CCU',
            'new': 'User',
            'order': 'Order',
            'order_gross': 'Order',
            'order_net': 'Order',
            'order_shopee': 'Order',
            'order_p2p': 'Order',
            'order_offline': 'Order',
            'order_third_party': 'Order',
            'gmv': 'GMV',
            'gmv_net': 'GMV',
            'gmv_shopee': 'GMV',
            'gmv_p2p': 'GMV',
            'gmv_offline': 'GMV',
            'gmv_third_party': 'GMV',
            'new_buyer': 'New Buyer',
            'txn_user': 'Txn User',
            'merchant': 'Merchant',
            'active': 'User',
            'active_ltz': 'User',
            'revenue_gross': 'Revenue',
            'revenue_gross_ltz': 'Revenue',
            'revenue_net': 'Revenue',
            'revenue_net_ltz': 'Revenue',
            'active_shop': 'Active Shop',
            'paying_shop': 'Paying Shop',
            'order_cb': 'Order',
            'order_sbs': 'Order',
            'order_shop': 'Order',
            'order_shopeefood': 'Order',
            'gmv_cb': 'GMV',
            'gmv_sbs': 'GMV',
            'gmv_shop': 'GMV',
            'gmv_shopeefood': 'GMV',
            'topup': 'Topup',
        };

        let grouppedMetrics = {};

        for (let linkDatum of linkData) {
            let metricGroup = ('metricGroup' in linkDatum)
                ? linkDatum.metricGroup
                : metricGroupMapping[linkDatum.metric];

            if (!(metricGroup in grouppedMetrics)) {
                grouppedMetrics[metricGroup] = []
            }
            grouppedMetrics[metricGroup].push(linkDatum);
        }

        return (
            <List component={"div"} disablePadding>
                {Object.keys(grouppedMetrics).map(key => this.renderMetricGroup(key, grouppedMetrics[key]))}
            </List>
        )
    }

    renderMetricGroup(label, linkData) {
        let uniqueIdentifier = linkData[0].config.product + label;
        return (
            <Fragment key={uniqueIdentifier + 'fragment'}>
                <ListItem alignItems = {'flex-start'}
                          className={'metrics__metric-group-title metric-group-title pseudo'}
                          dense
                          key={'listitem_metricgroup_' + uniqueIdentifier}
                >
                    <div className={'metric-group-title__text pseudo font--normal'} key={uniqueIdentifier + 'label'}>
                        {label}
                    </div>
                    <div className={'metric-group-title__line'} key={uniqueIdentifier + 'line'}> </div>
                </ListItem>
                {linkData.map(linkDatum => this.renderLink(linkDatum))}
            </Fragment>
        );
    }

    renderLink(link) {
        let linkUrl = this.generateLinkUrl(link);
        return (
            <SidebarLink
                key={linkUrl}
                link={linkUrl}
                label={link.label}
                additionalData={this.props.additionalData}/>
        )
    }

    setHover(isHover) {
        this.setState({
            hover: isHover
        });
    }

    render() {
        let isSingleProduct = this.props.additionalData.isSingleProduct;

        return (
            <div className={'sidebar__product product'}
                 onMouseEnter={() => this.setHover(true)}
                 onMouseLeave={() => this.setHover(false)}
            >

                <ListItem
                    key={'listitem' + this.props.label}
                    button
                    onClick={() => this.setState({open: !this.state.open})}
                    className={"pseudo product__container"}
                >
                    {/*Color bar (the one become blue when selected)*/}
                    <div className={'product__colorbar product__colorbar--' + (this.state.open ? 'open' : 'close')}>
                    </div>

                    {/*Label*/}
                    <ListItemText
                        primary={this.props.label}
                        classes={{
                            primary: 'pseudo product__label' +
                                    ' product__label--' + (this.state.open ? 'open' : 'close') +
                                    ' font--' + (this.state.open ? 'semi-bold' : 'normal')

                        }}
                    />

                    {/*Icon*/}
                    <div className={'product__icon-container ' +
                                    'product__icon-container--' + (this.state.open ? 'open ' : 'close ') +
                                    (this.state.hover ? 'product__icon-container--hover' : '')}>
                        {this.state.open ?
                            <ExpandLess classes={{root: 'product__collapse arrow-icon'}}/> :
                            <ExpandMore classes={{root: 'product__expand arrow-icon'}}/>
                        }
                    </div>

                </ListItem>

                {/*Metrics*/}
                <Collapse
                    in={isSingleProduct ? true : this.state.open}
                    timeout={'auto'}
                    unmountOnExit key={'collapse' + this.props.label}
                    className={'product__metrics metrics'}
                >
                    <div className={'metrics__padding metrics__padding-top'}> </div>
                    {this.renderMetrics(this.props.links)}
                    <div className={'metrics__padding metrics__padding-bottom'}> </div>
                </Collapse>
            </div>
        )
    }
}

export {Section};