import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

function Loading(props) {
    return (
        <div className={'loading'}>
            {props.isLoading && <CircularProgress size={30} classes={{root: 'loadingSymbol'}}/>}
        </div>
    )
}

export {Loading};