import React, {Component} from 'react';
import _ from 'lodash';
import {Properties} from "../../common/properties";
import {UserMetaUtils} from "../../common/utils";
import {Section} from "../../components/SidebarSection";


class BmgEditorSidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
        };
    }

    hasMetric(product, metric, frequencyBit) {
        return Boolean(this.props.userMeta.meta_data[product] &&
            UserMetaUtils.getMetaMetricKey(metric, frequencyBit) in this.props.userMeta.meta_data[product].metrics);
    }

    is_hiding(product, metric) {
        return metric === 'ccu' || metric.includes('revenue');

    }

    getGames() {
        let games = [];
        _.forEach(this.props.userMeta.data, (productConfig) => {
            if (_.includes(['games_api'], productConfig.product_group)) {
                if (_.includes(['bmg_editor', 'bmg_editor_mobile', 'bmg_editor_pc'], productConfig.product)) {
                    games.push(productConfig);
                }
            }
        });
        return games;
    }

    getLabelPostfix(gameConfig) {
        if (gameConfig.product === 'bmg_editor') {
            return ''
        }
        if (gameConfig.product.includes('mobile')) {
            return ' (Mobile)'
        }
        if (gameConfig.product.includes('pc')) {
            return ' (PC)'
        }
        return ` (${gameConfig.label})`
    }

    makeLinks(gameConfigs) {
        let links = [];
        [
            {metric: 'ccu', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime CCU'},
            {metric: 'ccu', frequencyBit: Properties.frequencyBit.daily, label: 'PCU'},
            {metric: 'active', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Active'},
            {metric: 'active', frequencyBit: Properties.frequencyBit.daily, label: 'User'},
            {metric: 'active_ltz', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Active LTZ'},
            {metric: 'active_ltz', frequencyBit: Properties.frequencyBit.daily, label: 'User LTZ'},
            {metric: 'revenue_gross', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Revenue (Gross)'},
            {metric: 'revenue_gross', frequencyBit: Properties.frequencyBit.daily, label: 'Revenue (Gross)'},
            {metric: 'revenue_net', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Revenue (Net)'},
            {metric: 'revenue_net', frequencyBit: Properties.frequencyBit.daily, label: 'Revenue (Net)'},
            {metric: 'revenue_gross_ltz', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Revenue (Gross) LTZ'},
            {metric: 'revenue_gross_ltz', frequencyBit: Properties.frequencyBit.daily, label: 'Revenue (Gross) LTZ'},
            {metric: 'revenue_net_ltz', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Revenue (Net) LTZ'},
            {metric: 'revenue_net_ltz', frequencyBit: Properties.frequencyBit.daily, label: 'Revenue (Net) LTZ'},
        ].forEach(({metric, frequencyBit, label}) => {
            _.map(gameConfigs, (gameConfig) => {
                if (this.hasMetric(gameConfig.product, metric, frequencyBit) && !this.is_hiding(gameConfig.product, metric)) {
                    links.push({
                        config: gameConfig,
                        metric: metric,
                        frequencyBit: frequencyBit,
                        label: label + this.getLabelPostfix(gameConfig)
                    })
                }
            })
        })
        return links
    };

    render() {
        let gameConfigs = this.getGames();
        return (
            <React.Fragment>
                {(<Section key={'section bmg_editor'}
                           productType={'games'}
                           links={this.makeLinks(gameConfigs)}
                           label={'BMG Editor'}
                           additionalData={this.props.additionalData}
                />)
                }
            </React.Fragment>
        )
    }
}

export {
    BmgEditorSidebar
};