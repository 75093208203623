import {DailyReport} from "../DailyReport";
import {Config} from './Foody.Config';
import _ from "lodash";
import {Utils} from "../../common/utils";
import {Properties} from "../../common/properties";

class FoodyDailyReport extends DailyReport {

    getConstructorStates() {
        const subtypes = this.getSubtypes();
        const defaultSubtype = _.first(subtypes.map(subtype => subtype.key));
        return {
            subtype: defaultSubtype,
            ...super.getConstructorStates(),
        };
    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        super.componentDidUpdate(prevProps, prevState, snapShot);
        let prevSubtype = _.get(prevState, 'subtype', undefined);
        let currentSubtype = _.get(this.state, 'subtype', undefined);

        if (currentSubtype !== prevSubtype) {
            this.getAndProcessData().then();
        }
    }

    getMetricData(metric) {
        metric = Config.getMetric(metric, this.state.subtype);
        return super.getMetricData(metric);
    }

    getSubtypes() {
        const subtypes = _.get(this.props.productConfig, 'subtypes', []);

        const userMetrics = this.props.productConfig.metrics.map(metric => metric.key);
        const metricGroup = this.props.metric;
        const metrics = Config.getMetricsFromMetricGroup(metricGroup).filter(metric => userMetrics.includes(metric));

        return subtypes.filter(
            (subtype) => metrics.some((metric) => metric.includes(subtype.key))
        );
    }

    onSubtypeChange(event) {
        let newSubtype = event.target.value;
        this.setState({
            subtype: newSubtype,
            dataDict: {},
        });
    }

    getBreakDownUrl(breakdownMetric) {
		let product = this.props.productConfig.product
		let metricGroup = breakdownMetric.replace(/_total|_shopee|_food|_ship|_market/g, '');
		return Utils.getUrlPath({
            matchUrl: '/' + product,
            metric: metricGroup,
            product: product,
            frequencyBit: Properties.frequencyBit.daily
        });
	}

    getRadioCategoriesProps() {
        return {
            configs: this.getSubtypes(),
            value: _.get(this.state, 'subtype', undefined),
            onChange: (event) => this.onSubtypeChange(event),
            title: 'Select Subtype',
        }
    }

    makeLabel(appLabel, reportLabel) {
        let subtypeLabel = _.find(this.getSubtypes(), subtype => subtype.key === this.state.subtype).label;
        return super.makeLabel(appLabel, reportLabel).replace(' - History', '') + ` (${subtypeLabel}) - History`;
    }
}

export {FoodyDailyReport};
