import React, {Component} from 'react';
import _ from 'lodash';
import {Properties} from "../../common/properties";
import {UserMetaUtils} from "../../common/utils";
import {Section} from "../../components/SidebarSection";


class SPXSidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
        };
    }

    hasMetric(product, metric, frequencyBit) {
        return Boolean(this.props.userMeta.meta_data[product] &&
            UserMetaUtils.getMetaMetricKey(metric, frequencyBit) in this.props.userMeta.meta_data[product].metrics);
    }

    makeLinks(spxConfig) {
        return _.filter([
            {config: spxConfig, metric: 'seller_arranged_order', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Seller Arranged Order', metricGroup: 'Order'},
            {config: spxConfig, metric: 'seller_arranged_order', frequencyBit: Properties.frequencyBit.daily, label: 'Seller Arranged Order', metricGroup: 'Order'},
            {config: spxConfig, metric: 'fulfilled_order', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Fulfilled Order', metricGroup: 'Order'},
            {config: spxConfig, metric: 'fulfilled_order', frequencyBit: Properties.frequencyBit.daily, label: 'Fulfilled Order', metricGroup: 'Order'},
            {config: spxConfig, metric: 'local_cdt', frequencyBit: Properties.frequencyBit.dateRange, label: 'Local CDT', metricGroup: 'Order'},
            {config: spxConfig, metric: 'lost_damaged_order', frequencyBit: Properties.frequencyBit.dateRange, label: 'Lost & Damaged', metricGroup: 'Order'},
        ], ({config, metric, frequencyBit}) => this.hasMetric(config.product, metric, frequencyBit));
    }

    render() {
        let spxConfig;
        if (this.props.userMeta.data) {
            for (let i = 0; i < this.props.userMeta.data.length; i++) {
                let productConfig = this.props.userMeta.data[i];
                if (productConfig.product_group === 'spx_api') {
                    spxConfig = productConfig;
                    break;
                }
            }
        }
        return (
            <React.Fragment>
                {spxConfig && <Section key={'section' + spxConfig.label}
                                          productType={'spx'}
                                          links={this.makeLinks(spxConfig)}
                                          label={spxConfig.label}
                                          additionalData={this.props.additionalData}
                                 />}
            </React.Fragment>
        )
    }
}

export {SPXSidebar};