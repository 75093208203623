import {DailyReport} from "../DailyReport"
import _ from "lodash";
import {Utils} from "../../common/utils";

class CcuReport extends DailyReport {
    componentDidUpdate(prevProps, prevState, snapShot) {
        super.componentDidUpdate(prevProps, prevState, snapShot);
        let prevSubcategory = _.get(prevState, 'subcategory', 'ccu');
        let currentSubcategory = _.get(this.state, 'subcategory', 'ccu');

        if (currentSubcategory !== prevSubcategory) {
            this.getAndProcessData().then();
        }
    }

    getMetricData(metric) {
        try {
            let metricSpecificParams = this.getMetricSpecificApiParams(metric);
            let reportSpecificParams = this.getReportSpecificApiParams();
            let params = {
                ...reportSpecificParams,
                ...metricSpecificParams
            };
            return Utils.getApiResponse(Utils.getApiPath({
                metric: _.get(this.state, 'subcategory', 'ccu'),
                product: this.props.productConfig.product,
                frequencyBit: this.getFrequencyBit()
            }), params)
        } catch (e) {
            return {errorMessage: e}
        }
    }

    getSubcategories() {
        let metricConfig = this.getMetricConfigFromMeta();
        if (!(metricConfig && metricConfig.subcategories)) {
            return [];
        }
        return metricConfig.subcategories.map(function (subcategory) {
            return {
                key: subcategory.key,
                label: subcategory.web_label
            }
        });
    }

    onSubcategoryChange(event) {
        let newSubcategory = event.target.value;
        this.setState({
            subcategory: newSubcategory,
            dataDict: {},
        });
    }

    getRadioCategoriesProps() {
        return {
            configs: this.getSubcategories(),
            value: _.get(this.state, 'subcategory', 'ccu'),
            onChange: (event) => this.onSubcategoryChange(event),
            title: 'Select Platform',
        }
    }

}

export {CcuReport}
