import React, {Component} from 'react';
import {Properties} from "../../common/properties";
import {UserMetaUtils} from "../../common/utils";
import {Section} from "../../components/SidebarSection";


class CreditSidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
        };
    }

    hasMetric(product, metric, frequencyBit) {
        return Boolean(this.props.userMeta.meta_data[product] &&
            UserMetaUtils.getMetaMetricKey(metric, frequencyBit) in this.props.userMeta.meta_data[product].metrics);
    }

    makeLinks(creditConfig) {
        const txnLinks = [
            {
                metric: 'txn',
                label: 'Realtime Txn',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Txn',
            },
            {
                metric: 'txn_spl',
                label: 'Realtime Txn (SPayLater)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Txn',
            },
            {
                metric: 'txn_spl-offline',
                label: 'Realtime Txn (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Txn',
            },
            {
                metric: 'txn_bcl',
                label: 'Realtime Txn (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Txn',
            },
            {
                metric: 'txn_tl',
                label: 'Realtime Txn (Term Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Txn',
            },
            {
                metric: 'txn_scl',
                label: 'Realtime Txn (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Txn',
            },
            {
                metric: 'txn_fel',
                label: 'Realtime Txn (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Txn',
            },
            {
                metric: 'txn_chp',
                label: 'Realtime Txn (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Txn',
            },
            {
                metric: 'txn',
                label: 'Txn',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Txn',
            },
            {
                metric: 'txn_spl',
                label: 'Txn (SPayLater)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Txn',
            },
            {
                metric: 'txn_spl-offline',
                label: 'Txn (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Txn',
            },
            {
                metric: 'txn_bcl',
                label: 'Txn (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Txn',
            },
            {
                metric: 'txn_tl',
                label: 'Txn (Term Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Txn',
            },      
            {
                metric: 'txn_scl',
                label: 'Txn (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Txn',
            },
            {
                metric: 'txn_fel',
                label: 'Txn (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Txn',
            },
            {
                metric: 'txn_chp',
                label: 'Txn (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Txn',
            },
        ]
        const tpvLinks = [
            {
                metric: 'tpv',
                label: 'Realtime Tpv',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv_spl',
                label: 'Realtime Tpv (SPayLater)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv_spl-offline',
                label: 'Realtime Tpv (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv_bcl',
                label: 'Realtime Tpv (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv_tl',
                label: 'Realtime Tpv (Term Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv_scl',
                label: 'Realtime Tpv (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv_fel',
                label: 'Realtime Tpv (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv_chp',
                label: 'Realtime Tpv (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv',
                label: 'Tpv',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv_spl',
                label: 'Tpv (SPayLater)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv_spl-offline',
                label: 'Tpv (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv_bcl',
                label: 'Tpv (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv_tl',
                label: 'Tpv (Term Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv_scl',
                label: 'Tpv (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv_fel',
                label: 'Tpv (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'TPV',
            },
            {
                metric: 'tpv_chp',
                label: 'Tpv (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'TPV',
            },
        ]
        const txnProportionLinks = [
            {
                metric: 'txn_proportion',
                label: 'Gross Order %',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Gross Order %',
            },
            {
                metric: 'txn_proportion_spl',
                label: 'Gross Order % (SPayLater)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Gross Order %',
            },
            {
                metric: 'txn_proportion_spl-offline',
                label: 'Gross Order % (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Gross Order %',
            },
            {
                metric: 'txn_proportion_bcl',
                label: 'Gross Order % (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Gross Order %',
            },
            {
                metric: 'txn_proportion_tl',
                label: 'Gross Order % (Term Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Gross Order %',
            },
            {
                metric: 'txn_proportion_scl',
                label: 'Gross Order % (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Gross Order %',
            },
            {
                metric: 'txn_proportion_fel',
                label: 'Gross Order % (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Gross Order %',
            },
            {
                metric: 'txn_proportion_chp',
                label: 'Gross Order % (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Gross Order %',
            },
        ]
        const gmvProportionLinks = [
            {
                metric: 'gmv_proportion',
                label: 'Gmv %',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'GMV %',
            },
            {
                metric: 'gmv_proportion_spl',
                label: 'Gmv % (SPayLater)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'GMV %',
            },
            {
                metric: 'gmv_proportion_spl-offline',
                label: 'Gmv % (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'GMV %',
            },
            {
                metric: 'gmv_proportion_bcl',
                label: 'Gmv % (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'GMV %',
            },
            {
                metric: 'gmv_proportion_tl',
                label: 'Gmv % (Term Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'GMV %',
            },
            {
                metric: 'gmv_proportion_scl',
                label: 'Gmv % (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'GMV %',
            },
            {
                metric: 'gmv_proportion_fel',
                label: 'Gmv % (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'GMV %',
            },
            {
                metric: 'gmv_proportion_chp',
                label: 'Gmv % (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'GMV %',
            },
        ]
        const loanOutstandingLinks = [
            {
                metric: 'loan_outstanding',
                label: 'Realtime Loan O/S',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding_spl',
                label: 'Realtime Loan O/S (SPayLater)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding_spl-offline',
                label: 'Realtime Loan O/S (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding_bcl',
                label: 'Realtime Loan O/S (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding_tl',
                label: 'Realtime Loan O/S (Term Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding_scl',
                label: 'Realtime Loan O/S (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding_fel',
                label: 'Realtime Loan O/S (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding_chp',
                label: 'Realtime Loan O/S (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding',
                label: 'Loan O/S',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding_spl',
                label: 'Loan O/S (SPayLater)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding_spl-offline',
                label: 'Loan O/S (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding_bcl',
                label: 'Loan O/S (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding_tl',
                label: 'Loan O/S (Term Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding_scl',
                label: 'Loan O/S (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding_fel',
                label: 'Loan O/S (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S',
            },
            {
                metric: 'loan_outstanding_chp',
                label: 'Loan O/S (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S',
            },
        ]
        const loanOutstandingDiffLinks = [
            {
                metric: 'loan_outstanding_diff',
                label: 'Realtime Loan O/S Diff',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff_spl',
                label: 'Realtime Loan O/S Diff (SPayLater)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff_spl-offline',
                label: 'Realtime Loan O/S Diff (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff_bcl',
                label: 'Realtime Loan O/S Diff (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff_tl',
                label: 'Realtime Loan O/S Diff (Term Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff_scl',
                label: 'Realtime Loan O/S Diff (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff_fel',
                label: 'Realtime Loan O/S Diff (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff_chp',
                label: 'Realtime Loan O/S Diff (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff',
                label: 'Loan O/S Diff',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff_spl',
                label: 'Loan O/S Diff (SPayLater)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff_spl-offline',
                label: 'Loan O/S Diff (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff_bcl',
                label: 'Loan O/S Diff (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff_tl',
                label: 'Loan O/S Diff (Term Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff_scl',
                label: 'Loan O/S Diff (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff_fel',
                label: 'Loan O/S Diff (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S Diff',
            },
            {
                metric: 'loan_outstanding_diff_chp',
                label: 'Loan O/S Diff (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan O/S Diff',
            },
        ]
        const loanDisbursedLinks = [
            {
                metric: 'loan_disbursed',
                label: 'Realtime Loan Disbursed',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed_spl',
                label: 'Realtime Loan Disbursed (SPayLater)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed_spl-offline',
                label: 'Realtime Loan Disbursed (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed_bcl',
                label: 'Realtime Loan Disbursed (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed_tl',
                label: 'Realtime Loan Disbursed (Term Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed_scl',
                label: 'Realtime Loan Disbursed (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed_fel',
                label: 'Realtime Loan Disbursed (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed_chp',
                label: 'Realtime Loan Disbursed (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed',
                label: 'Loan Disbursed',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed_spl',
                label: 'Loan Disbursed (SPayLater)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed_spl-offline',
                label: 'Loan Disbursed (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed_bcl',
                label: 'Loan Disbursed (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed_tl',
                label: 'Loan Disbursed (Term Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed_scl',
                label: 'Loan Disbursed (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed_fel',
                label: 'Loan Disbursed (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan Disbursed',
            },
            {
                metric: 'loan_disbursed_chp',
                label: 'Loan Disbursed (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Loan Disbursed',
            },
        ]
        const effectiveBorrowersLinks = [
            {
                metric: 'effective_borrowers',
                label: 'Realtime Effective Borrowers',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers_spl',
                label: 'Realtime Effective Borrowers (SPayLater)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers_spl-offline',
                label: 'Realtime Effective Borrowers (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers_bcl',
                label: 'Realtime Effective Borrowers (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers_tl',
                label: 'Realtime Effective Borrowers (Term Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers_scl',
                label: 'Realtime Effective Borrowers (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers_fel',
                label: 'Realtime Effective Borrowers (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers_chp',
                label: 'Realtime Effective Borrowers (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers',
                label: 'Effective Borrowers',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers_spl',
                label: 'Effective Borrowers (SPayLater)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers_spl-offline',
                label: 'Effective Borrowers (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers_bcl',
                label: 'Effective Borrowers (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers_tl',
                label: 'Effective Borrowers (Term Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers_scl',
                label: 'Effective Borrowers (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers_fel',
                label: 'Effective Borrowers (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Effective Borrowers',
            },
            {
                metric: 'effective_borrowers_chp',
                label: 'Effective Borrowers (Handphone Loanw)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Effective Borrowers',
            },
        ]
        const newBorrowersLinks = [
            {
                metric: 'new_borrowers',
                label: 'Realtime New Borrowers',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers_spl',
                label: 'Realtime New Borrowers (SPayLater)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers_spl-offline',
                label: 'Realtime New Borrowers (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers_bcl',
                label: 'Realtime New Borrowers (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers_tl',
                label: 'Realtime New Borrowers (Term Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers_scl',
                label: 'Realtime New Borrowers (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers_fel',
                label: 'Realtime New Borrowers (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers_chp',
                label: 'Realtime New Borrowers (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.realtime,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers',
                label: 'New Borrowers',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers_spl',
                label: 'New Borrowers (SPayLater)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers_spl-offline',
                label: 'New Borrowers (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers_bcl',
                label: 'New Borrowers (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers_tl',
                label: 'New Borrowers (Term Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers_scl',
                label: 'New Borrowers (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers_fel',
                label: 'New Borrowers (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'New Borrowers',
            },
            {
                metric: 'new_borrowers_chp',
                label: 'New Borrowers (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'New Borrowers',
            },
        ]
        const outstandingBorrowersLinks = [
            {
                metric: 'outstanding_borrowers',
                label: 'Outstanding Borrowers',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Outstanding Borrowers',
            },
            {
                metric: 'outstanding_borrowers_spl',
                label: 'Outstanding Borrowers (SPayLater)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Outstanding Borrowers',
            },
            {
                metric: 'outstanding_borrowers_spl-offline',
                label: 'Outstanding Borrowers (SPayLater - Offline)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Outstanding Borrowers',
            },
            {
                metric: 'outstanding_borrowers_bcl',
                label: 'Outstanding Borrowers (Buyer Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Outstanding Borrowers',
            },
            {
                metric: 'outstanding_borrowers_tl',
                label: 'Outstanding Borrowers (Term Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Outstanding Borrowers',
            },
            {
                metric: 'outstanding_borrowers_scl',
                label: 'Outstanding Borrowers (Seller Cash Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Outstanding Borrowers',
            },
            {
                metric: 'outstanding_borrowers_fel',
                label: 'Outstanding Borrowers (Fast Escrow)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Outstanding Borrowers',
            },
            {
                metric: 'outstanding_borrowers_chp',
                label: 'Outstanding Borrowers (Handphone Loan)',
                frequencyBit: Properties.frequencyBit.daily,
                metricGroup: 'Outstanding Borrowers',
            },
        ];

        const links = [
            ...txnLinks,
            ...tpvLinks,
            ...txnProportionLinks,
            ...gmvProportionLinks,
            ...loanOutstandingLinks,
            ...loanOutstandingDiffLinks,
            ...loanDisbursedLinks,
            ...effectiveBorrowersLinks,
            ...newBorrowersLinks,
            ...outstandingBorrowersLinks,
        ];
        
        return links
            .map(l => ({ ...l, config: creditConfig }))
            .filter(
                ({ config, metric, frequencyBit }) => this.hasMetric(config.product, metric, frequencyBit)
            );
    }

    render() {
        let creditConfig;
        if (this.props.userMeta.data) {
            for (let i = 0; i < this.props.userMeta.data.length; i++) {
                let productConfig = this.props.userMeta.data[i];
                if (productConfig.product_group === 'credit_api') {
                    creditConfig = productConfig;
                    break;
                }
            }
        }

        return (
            <React.Fragment>
                {creditConfig && <Section key={'section' + creditConfig.label}
                                        productType={'credit'}
                                        links={this.makeLinks(creditConfig)}
                                        label={creditConfig.label}
                                        additionalData={this.props.additionalData}
                               />}
            </React.Fragment>
        )
    }
}

export {CreditSidebar};