// 'Pseudo' class here just to increase selectivity to override default material UI instead of using !important

import React, {Component} from 'react';
import _ from 'lodash';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import GamesIcon from '../assets/images/icon_games_keystats.png'
import ShopeepayIcon from '../assets/images/icon_shopeepay_keystats.png'
import KeystatsIcon from '../assets/images/icon_keystats.png'
import ShopeefoodIcon from '../assets/images/icon_shopeefood_keystats.png'
import OchaIcon from '../assets/images/icon_ocha_keystats.png'
import ShopeeIcon from '../assets/images/icon_shopee_keystats.png'
import SeabankIcon from '../assets/images/icon_seabank_keystats.png'
import CreditIcon from '../assets/images/icon_credit_keystats.png'
import SPXIcon from '../assets/images/icon_spx_keystats.png'
import DummyAvatar from '../assets/images/profile_photo.jpg'

import '../assets/css/Header.css';
import {Utils} from '../common/utils.jsx';
import {ApiPath} from '../common/properties.jsx'

class Header extends Component {

    static getProductDetails(product) {
        let icon;
        let title;
        switch (product) {
            case 'games':
                icon = GamesIcon;
                title = 'Games Keystats';
                break;
            case 'shopeepay':
                icon = ShopeepayIcon;
                title = 'ShopeePay Keystats';
                break;
            case 'shopee':
                icon = ShopeeIcon;
                title = 'Shopee Keystats';
                break;
            case 'foody':
                icon = ShopeefoodIcon;
                title = 'ShopeeFood Keystats';
                break;
            case 'ocha':
                icon = OchaIcon;
                title = 'Ocha Keystats';
                break;
            case 'money':
                icon = SeabankIcon;
                title = 'SeaBank Keystats';
                break;
            case 'keystats':
                icon = KeystatsIcon;
                title = 'Keystats';
                break;
            case 'credit':
                icon = CreditIcon;
                title = 'Credit Keystats';
                break;
            case 'spx':
                icon = SPXIcon;
                title = 'SPX Keystats';
                break;
            default:
                icon = KeystatsIcon;
                title = 'Keystats';
                break;
        }
        return {
            icon: icon,
            title: title
        }
    }

    constructor(props) {
        super(props);
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
        this.state = {
            product: _.get(props.meta, 'client', 'keystats'),
            profileOpen: false,
        };
    }

    handleClose(event) {
        let button = document.getElementById('profile');
        if (button.contains(event.target) && this.state.profileOpen) {
            return;
        }
        this.setState({profileOpen: false});
    }

    handleLogout(event) {
        this.handleClose(event);
        let logoutPath = ApiPath.logout;
        Promise.resolve(Utils.getApiResponse(logoutPath))
               .then(_ => document.location.replace(ApiPath.home));
    }

    handleToggle() {
        this.setState(prevState => ({
            profileOpen: !prevState.profileOpen
        }));

    }

    renderProfile() {
        let displayName = _.get(this.props.meta.user_details, 'display_name', 'User');
        let pic = _.get(this.props.meta.user_details, 'pic', DummyAvatar);
        return (
            <Button
                ref=''
                aria-controls="profile__menu"
                aria-haspopup="true"
                onClick={() => this.handleToggle()}
                id="profile"
                className="toolbar__profile profile pseudo"
            >
                {this.getProfilePictureFromLink(pic)}
                <Typography className="profile__name pseudo font--normal">
                    {displayName}
                </Typography>
            </Button>
        )
    }

    getProfilePictureFromLink(link) {
        return (
            <img src={link} className={"profile__picture"} alt="" onError={event => {event.target.src = DummyAvatar}}/>
        );
    }

    renderProfilePopup() {
        return (
            <Popper open={this.state.profileOpen}
                    anchorEl={document.getElementById("profile")}
                    transition disablePortal
            >
                {({ TransitionProps}) => (
                    <Grow
                        {...TransitionProps}
                    >
                        <Paper id='profile__menu' className='profile__menu menu pseudo'>
                            <ClickAwayListener onClickAway={event => this.handleClose(event)}>
                                <MenuList className='menu__list pseudo'>
                                    <MenuItem onClick={event => this.handleLogout(event)}
                                              className='menu__item pseudo font--normal'
                                    >
                                        <span className='menu__text'>
                                            Logout
                                        </span>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        )
    }

    render() {
        let productDetails = Header.getProductDetails(this.state.product);
        let iconSource = productDetails.icon;
        let productTitle = productDetails.title;

        return (
            <AppBar position='fixed' classes={{root: 'header pseudo'}}>
                <Toolbar classes={{root: 'header__toolbar toolbar pseudo'}}>
                    <img src={iconSource} alt='' className='toolbar__logo'/>
                    <Typography className='toolbar__title font--bold pseudo'>
                        {productTitle}
                    </Typography>
                    {this.renderProfile()}
                    {this.renderProfilePopup()}
                </Toolbar>
            </AppBar>
        );
    }
}

export default Header;
