import React, {Component} from 'react';
import _, {isEmpty} from "lodash";
import * as moment from "moment-timezone";
import {Utils} from "../common/utils";
import {ReportSettings} from "../components/ReportSettings";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = this.getConstructorStates();
    }

    getConstructorStates() {
        return {
            dataDict: {},
            regions: [],
            displayRegions: {},
            errorMessage: '',
            announcements: [],
            nestedMetrics: {},
            isLoading: false,
            metricPeriods: {},
            dataGroups: [],
        }
    }

    getNow() {
        let timezone = _.get(_.get(this.props, 'extraProps', {}), 'timezone', 'Asia/Singapore');
        return moment().tz(timezone);
    }

    // type == 'data' or 'metaData' (getDataKey and getMetaKey)
    // subgroup here is region key (or provider key in topup)
    makeDataDictKey(metric, type, date, subgroup, period) {
        return `${metric}||${type}||${date}||${subgroup}||${period}`;
    }

    getDates() {
        return [];
    }

    getDataKey() {
        return 'data';
    }

    getMetaKey() {
        return 'metaData';
    }

    getDefaultPeriod() {
        return 1;
    }

    getMetrics() {
        return [this.props.metric];
    }

    componentDidMount() {
        this.getAndProcessData().then();
    }

    componentDidUpdate(prevProps, prevState, snapShot) {
    }

    async getAndProcessData() {
        let loadingState = this.getInitialLoadingState();
        this.setState(loadingState);

        let errorMessage = '';

        Promise.all(this.getMetrics()
            .map((metric, metricIndex) => this.getAndProcessMetricData(metric, metricIndex === 0)))
            .then(errors => errors.forEach(error => errorMessage += `${error} `))
            .then(() => this.setState(this.getFinishedLoadingState(errorMessage)));
    }

    getInitialLoadingState() {
        return {
            errorMessage: '',
            isLoading: true
        };
    }

    getFinishedLoadingState(errorMessage) {
        return {
            errorMessage: errorMessage,
            isLoading: false
        }
    }

    async getAndProcessMetricData(metric, needToUpdateInitialState) {
        let response = await this.getMetricData(metric);
        let errorMessage = '';
        try {
            this.updateRegionStatesFromResponse(response, needToUpdateInitialState);
            this.updatePeriodStateFromResponse(response, metric);
            this.updateDataStateFromResponse(response, metric);
            this.updateOtherStatesFromResponse(response, needToUpdateInitialState);
            this.updateAnnouncementsStatesFromResponse(response, needToUpdateInitialState);
            this.updateNestedMetricsStatesFromResponse(response, needToUpdateInitialState);
        } catch (e) {
            errorMessage = e;
        }


        return errorMessage;
    }

    getMetricData(metric) {
        try {
            let metricSpecificParams = this.getMetricSpecificApiParams(metric);
            let reportSpecificParams = this.getReportSpecificApiParams();
            let params = {
                ...reportSpecificParams,
                ...metricSpecificParams
            };
            return Utils.getApiResponse(Utils.getApiPath({
                metric: metric,
                product: this.props.productConfig.product,
                frequencyBit: this.getFrequencyBit()
            }), params)
        } catch (e) {
            return {errorMessage: e}
        }
    }

    getReportSpecificApiParams() {
        const layers = this.getPrevLayers()
        if (layers.length > 0) {
            return {
                region: layers[0].regionKey,
            };
        }
        return {};
    }

    getMetricSpecificApiParams(metric) {
        return {};
    }

    getFrequencyBit() {
        return 0;
    }

    getSubgroups() {
        return this.state.dataGroups;
    }

    getRegionKeyFromSubgroup(subgroup) {
        const layers = this.getPrevLayers()
        if (layers.length > 0) {
            return layers[0].regionKey
        }
        return subgroup.key;
    }

    updateRegionStatesFromResponse(response, needToUpdateInitialState) {
        if (!needToUpdateInitialState) {
            return;
        }
        let regions = _.uniqBy(response.data.map((datum) => {
            return {
                key: _.get(datum, 'meta_data', {}).region,
                label: _.get(datum, 'meta_data', {}).region_label,
            }
        }), (datum) => datum.key);

        let dataGroups = _.uniqBy(response.data.map((datum) => {
            return {
                key: _.get(datum, 'meta_data', {}).data_key,
                label: _.get(datum, 'meta_data', {}).data_label,
            }
        }), (datum) => datum.key);

        this.setInitialRegionState(regions, dataGroups);
    }

    setInitialRegionState(regions, dataGroups) {
        let displayRegions = _.zipObject(
            regions.map(region => region.key),
            this.getInitialRegionDisplay(regions)
        );

        this.setState({
            regions: regions,
            displayRegions: displayRegions,
            dataGroups: dataGroups,
        })
    }

    getInitialRegionDisplay(regions) {
        let currentRegionDisplay = _.get(this.state, 'displayRegions', {});

        // get current setting if possible
        return regions.map(region => _.get(currentRegionDisplay, region.key, true));
    }

    updatePeriodStateFromResponse(response, metric) {
        let metricPeriods = new Set();
        response.data.forEach((groupData) => {
            let period = _.get(_.get(groupData, 'meta_data', {}), 'period', this.getDefaultPeriod());
            metricPeriods.add(period);
        });
        let currentMetricPeriods = this.state.metricPeriods;
        currentMetricPeriods[metric] = Array.from(metricPeriods);
        this.setState({metricPeriods: currentMetricPeriods})
    }

    updateDataStateFromResponse(response, metric) {
        let dataDict = {};

        response.data.forEach(groupData => {
            let data = _.get(groupData, 'data', []);
            let metaData = _.get(groupData, 'meta_data', {});
            let subgroup = this.getSubgroupFromGroupDataMeta(metaData);
            let period = _.get(metaData, 'period', this.getDefaultPeriod());
            let dateMetaData = this.getDateFromMetaData(metaData);

            let metaDataKey = this.makeDataDictKey(metric, this.getMetaKey(), dateMetaData, subgroup, period);
            dataDict[metaDataKey] = metaData;

            dataDict = {
                ...dataDict,
                ...this.updateDataDictFromData(data, metric, dateMetaData, subgroup, period)
            };
        });
        this.setState({
            dataDict: {
                ...this.state.dataDict,
                ...dataDict
            }
        });
    }

    // Subgroup in here mostly is region key, except for topup (its provider key)
    getSubgroupFromGroupDataMeta(metaData) {
        return metaData.data_key
    }

    getDateFromMetaData(metaData) {
        return metaData.date;
    }

    updateDataDictFromData(data, metric, dateMetaData, subgroup, period) {
        let updateDataDict = {};
        let dataKey = this.makeDataDictKey(metric, this.getDataKey(), dateMetaData, subgroup, period);
        updateDataDict[dataKey] = data;
        return updateDataDict;
    }

    updateOtherStatesFromResponse(response, needToUpdateInitialState) {
    }

    updateAnnouncementsStatesFromResponse(response, needToUpdateInitialState) {
        if (!needToUpdateInitialState) {
            return;
        }
        const announcements = response.announcements || [];

        this.setState({
            announcements: announcements,
        });
    }

    updateNestedMetricsStatesFromResponse(response, needToUpdateInitialState) {
        if (!needToUpdateInitialState) {
            return;
        }
        const nestedMetrics = {}
        response.data.forEach((regionData) => {
            const dataKey = regionData['meta_data'].data_key;
            nestedMetrics[dataKey] = regionData['meta_data']['nested_metrics']
        })
        this.setState({nestedMetrics})

    }

    async handleDateSubmit() {
        this.setState({errorMessage: ''});
        await this.getAndProcessData();
    }

    makeLabel(appLabel, reportLabel) {
        const layers = this.getPrevLayers()
        let labels = layers.map(layer => _.get(layer, 'reportLabel'));

        if (labels.length >= 1 && this.state.regions.length === 1) {
            labels.splice(1, 0, this.state.regions[0].label)
        }
        labels = _.concat(labels, this.props.reportLabel)

        return labels.join(' - ')
    }

    renderLabel(labelText) {
        return (<React.Fragment>
            {this.makeBackButton()}
            {labelText}
        </React.Fragment>)
    }

    isAbleToSelectAllRegions() {
        return true;
    }

    onRegionToggle(selectedRegionKeys) {
        let displayRegions = _.cloneDeep(this.state.displayRegions);
        selectedRegionKeys.forEach(selectedRegionKey => {
            displayRegions[selectedRegionKey] = !displayRegions[selectedRegionKey]
        });
        this.setState({displayRegions});
    }

    makeDatepicker() {
        return (<React.Fragment/>);
    }

    makeDownloadButton() {
        return (<React.Fragment/>);
    }

    getMetricConfigFromMeta() {
        let metric = this.props.metric;
        let frequencyBit = this.props.frequency;
        let productConfig = this.props.productConfig;
        return productConfig.metrics.find(
            (metricConfig) => metricConfig.key === metric && metricConfig.frequency === frequencyBit
        );
    }

    getRadioCategoriesProps() {
        return {}
    }

    makeReport() {
        return (<React.Fragment/>);
    }

    getPrevLayers() {
        return _.get(this, 'props.location.state.layers', [])
    }

    makeBackButton() {
        const layers = this.getPrevLayers()
        if (layers !== undefined && layers.length > 0) {
            return (
                <Button
                    className={'report__back-link parent-link font--semi-bold'}
                    component={(itemProps) => (
                        <Link to={{
                            pathname: _.last(layers).path,
                            state: {
                                layers: layers.slice(0, -1),
                                valueProps: this.getValueProps()
                            }
                        }} {...itemProps}/>)}
                >
                    <span className={'parent-link__back-arrow'}>{'<'}</span>
                    Back
                </Button>
            )
        }
        return <React.Fragment/>
    }

    getBreakDownUrl(breakdownMetric) {
        let product = this.props.productConfig.product
        return Utils.getUrlPath({
            matchUrl: '/' + product,
            metric: breakdownMetric,
            product: product,
            frequencyBit: this.getFrequencyBit()
        })
    }

    getBreakDownLabel(breakdownMetricLabel) {
        if (breakdownMetricLabel.includes('Telco')) {
            return 'By Telco';
        }
        if (breakdownMetricLabel.includes('State')) {
            return 'By State';
        }
        if (breakdownMetricLabel.includes('City')) {
            return 'By City';
        }
        if (breakdownMetricLabel.includes('Area')) {
            return 'By Area';
        }
        return breakdownMetricLabel
    }

    getBreakdownItems(subgroup) {
        const nestedMetrics = this.state.nestedMetrics[subgroup.key]
        const layers = this.getPrevLayers()
        const current = this.getCurrentLayerDetails(subgroup)
        const withCurLayers = _.concat(layers.slice(0), current)
        const breakdownItems = []
        if (nestedMetrics != null) {
            nestedMetrics.forEach(metric => {
                const updatedLabel = this.getBreakDownLabel(metric.label)
                const pathname = this.getBreakDownUrl(metric.key);
                breakdownItems.push(this.renderBreakDown(pathname, updatedLabel, withCurLayers, this.getValueProps()))
            })
        }
        return breakdownItems;
    }

    getValueProps() {
        let valueProps = _.get(this.props, 'valueProps', {})
        if (isEmpty(valueProps)) {
            valueProps = _.get(this.props, 'location.state.valueProps', {}) //get valueProps from parent
        }
        return valueProps
    }

    renderNestedLink(pathname, layers, valueProps) {
        return ((itemProps) => (<Link
            to={{
                pathname: pathname,
                state: {
                    layers: layers,
                    valueProps: valueProps,
                },
            }}
            {...itemProps}
        />))
    }

    getCurrentLayerDetails(subgroup) {
        return {
            regionKey: subgroup.key,
            regionLabel: subgroup.label,
            path: this.props.location.pathname,
            reportLabel: this.props.reportLabel,
        }
    }

    render() {
        let props = {
            regions: this.state.regions.length > 1 ? this.state.regions : [],
            isAbleToSelectAllRegions: this.isAbleToSelectAllRegions(),
            displayRegions: this.state.displayRegions,
            label: this.renderLabel(this.makeLabel(this.props.productConfig.label, this.props.reportLabel)),
            datepicker: this.makeDatepicker(),
            downloadButton: this.makeDownloadButton(),
            isLoading: this.state.isLoading,
            errorMessage: this.state.errorMessage,
            announcements: this.state.announcements,
            nestedMetrics: this.state.nestedMetrics,
            onRegionToggle: (regionKeys) => this.onRegionToggle(regionKeys),
            radioCategoriesProps: this.getRadioCategoriesProps(),
            report: this.makeReport(),
        };
        return (
            <ReportSettings {...props}/>
        );
    }
}

export {Report};
