import {AbstractRouter} from "../Router";
import {RealtimeReport} from '../RealtimeReport';
import {DailyReport} from "../DailyReport";
import {Properties} from "../../common/properties";


class AirpayRouter extends AbstractRouter {

    getConfigDicts() {
        let realtimeConfigDicts = this.getRealtimeConfigDicts();
        let dailyConfigDicts = this.getDailyConfigDicts();
        return [
            ...realtimeConfigDicts,
            ...dailyConfigDicts,
        ];
    }

    getGeneralConfigDicts() {
        return [
            {metric: 'ccu', reportLabel: 'CCU'},
            {metric: 'new_register', reportLabel: 'New Register'},
            {metric: 'new_kyc', reportLabel: 'New KYC'},
            {metric: 'new_txn_user', reportLabel: 'New Txn User'},

            {metric: 'txn_user', reportLabel: 'Txn User'},
            {metric: 'txn_user_mp', reportLabel: 'Txn User (ShopeePay - Shopee (non-DP/Food))'},
            {metric: 'txn_user_dp', reportLabel: 'Txn User (ShopeePay - DP)'},
            {metric: 'txn_user_food', reportLabel: 'Txn User (ShopeePay - ShopeeFood)'},
            {metric: 'txn_user_mitra', reportLabel: 'Txn User (ShopeePay - Mitra)'},
            {metric: 'txn_user_garena', reportLabel: 'Txn User (ShopeePay - Garena)'},
            {metric: 'txn_user_credit_repayment', reportLabel: 'Txn User (ShopeePay - Credit Repayment)'},
            {metric: 'txn_user_other_dfs', reportLabel: 'Txn User (ShopeePay - Other DFS)'},
            {metric: 'txn_user_p2p', reportLabel: 'Txn User (ShopeePay - P2P)'},
            {metric: 'txn_user_p2moffline', reportLabel: 'Txn User (ShopeePay - P2M Offline)'},
            {metric: 'txn_user_p2monline', reportLabel: 'Txn User (ShopeePay - P2M Online)'},

            {metric: 'txn_user_by_app_type', reportLabel: 'App'},
            {metric: 'txn_user_mp_by_app_type', reportLabel: 'App'},
            {metric: 'txn_user_dp_by_app_type', reportLabel: 'App'},
            {metric: 'txn_user_food_by_app_type', reportLabel: 'App'},
            {metric: 'txn_user_mitra_by_app_type', reportLabel: 'App'},
            {metric: 'txn_user_garena_by_app_type', reportLabel: 'App'},
            {metric: 'txn_user_credit_repayment_by_app_type', reportLabel: 'App'},
            {metric: 'txn_user_other_dfs_by_app_type', reportLabel: 'App'},
            {metric: 'txn_user_p2p_by_app_type', reportLabel: 'App'},
            {metric: 'txn_user_p2moffline_by_app_type', reportLabel: 'App'},
            {metric: 'txn_user_p2monline_by_app_type', reportLabel: 'App'},

            {metric: 'txn', reportLabel: 'Txn'},
            {metric: 'txn_mp', reportLabel: 'Txn (ShopeePay - Shopee (non-DP/Food))'},
            {metric: 'txn_dp', reportLabel: 'Txn (ShopeePay - DP)'},
            {metric: 'txn_food', reportLabel: 'Txn (ShopeePay - ShopeeFood)'},
            {metric: 'txn_mitra', reportLabel: 'Txn (ShopeePay - Mitra)'},
            {metric: 'txn_garena', reportLabel: 'Txn (ShopeePay - Garena)'},
            {metric: 'txn_credit_repayment', reportLabel: 'Txn (ShopeePay - Credit Repayment)'},
            {metric: 'txn_other_dfs', reportLabel: 'Txn (ShopeePay - Other DFS)'},
            {metric: 'txn_p2p', reportLabel: 'Txn (ShopeePay - P2P)'},
            {metric: 'txn_p2moffline', reportLabel: 'Txn (ShopeePay - P2M Offline)'},
            {metric: 'txn_p2monline', reportLabel: 'Txn (ShopeePay - P2M Online)'},

            {metric: 'txn_by_app_type', reportLabel: 'App'},
            {metric: 'txn_mp_by_app_type', reportLabel: 'App'},
            {metric: 'txn_dp_by_app_type', reportLabel: 'App'},
            {metric: 'txn_food_by_app_type', reportLabel: 'App'},
            {metric: 'txn_mitra_by_app_type', reportLabel: 'App'},
            {metric: 'txn_garena_by_app_type', reportLabel: 'App'},
            {metric: 'txn_credit_repayment_by_app_type', reportLabel: 'App'},
            {metric: 'txn_other_dfs_by_app_type', reportLabel: 'App'},
            {metric: 'txn_p2p_by_app_type', reportLabel: 'App'},
            {metric: 'txn_p2moffline_by_app_type', reportLabel: 'App'},
            {metric: 'txn_p2monline_by_app_type', reportLabel: 'App'},

            {metric: 'tpv', reportLabel: 'TPV'},
            {metric: 'tpv_mp', reportLabel: 'TPV (ShopeePay - Shopee (non-DP/Food))'},
            {metric: 'tpv_dp', reportLabel: 'TPV (ShopeePay - DP)'},
            {metric: 'tpv_food', reportLabel: 'TPV (ShopeePay - ShopeeFood)'},
            {metric: 'tpv_mitra', reportLabel: 'TPV (ShopeePay - Mitra)'},
            {metric: 'tpv_garena', reportLabel: 'TPV (ShopeePay - Garena)'},
            {metric: 'tpv_credit_repayment', reportLabel: 'TPV (ShopeePay - Credit Repayment)'},
            {metric: 'tpv_other_dfs', reportLabel: 'TPV (ShopeePay - Other DFS)'},
            {metric: 'tpv_p2p', reportLabel: 'TPV (ShopeePay - P2P)'},
            {metric: 'tpv_p2moffline', reportLabel: 'TPV (ShopeePay - P2M Offline)'},
            {metric: 'tpv_p2monline', reportLabel: 'TPV (ShopeePay - P2M Online)'},

            {metric: 'tpv_by_app_type', reportLabel: 'App'},
            {metric: 'tpv_mp_by_app_type', reportLabel: 'App'},
            {metric: 'tpv_dp_by_app_type', reportLabel: 'App'},
            {metric: 'tpv_food_by_app_type', reportLabel: 'App'},
            {metric: 'tpv_mitra_by_app_type', reportLabel: 'App'},
            {metric: 'tpv_garena_by_app_type', reportLabel: 'App'},
            {metric: 'tpv_credit_repayment_by_app_type', reportLabel: 'App'},
            {metric: 'tpv_other_dfs_by_app_type', reportLabel: 'App'},
            {metric: 'tpv_p2p_by_app_type', reportLabel: 'App'},
            {metric: 'tpv_p2moffline_by_app_type', reportLabel: 'App'},
            {metric: 'tpv_p2monline_by_app_type', reportLabel: 'App'},
        ];
    }

    getRealtimeConfigDicts() {
        let realtimeConfigDicts = this.getGeneralConfigDicts();
        return realtimeConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopeepay_api',
                ...configDict
            }
        });
    }

    getDailyConfigDicts() {
        let dailyConfigDicts = this.getGeneralConfigDicts();
        return dailyConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopeepay_api',
                ...configDict
            }
        });
    }

    getMatchUrl() {
        return '/shopeepay';
    }
}

export {AirpayRouter};