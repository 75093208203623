import {AbstractRouter} from "../Router";
import {RealtimeCcuReport} from "./Shopee.RealtimeCcuReport";
import {CcuReport} from "./Shopee.CcuReport";
import {RealtimeReport} from "../RealtimeReport";
import {DailyReport} from "../DailyReport";
import {Properties} from "../../common/properties";


class ShopeeRouter extends AbstractRouter {

    getConfigDicts() {
        let dailyConfigDicts = this.getDailyConfigDicts();
        let ccuConfigDicts = this.getCcuConfigDicts();
        let newConfigDicts = this.getNewConfigDicts();
        let newBuyerConfigDicts = this.getNewBuyerConfigDicts();
        let newInstallConfigDicts = this.getNewInstallConfigDicts();
        let txnConfigDicts = this.getTxnConfigDicts();
        let txnShopConfigDicts = this.getTxnShopConfigDicts();
        let txnCbConfigDicts = this.getTxnCbConfigDicts();
        let txnShopeeFoodConfigDicts = this.getTxnShopeeFoodConfigDicts();
        let txnUserConfigDicts = this.getTxnUserConfigDicts();
        let activeUserConfigDicts = this.getActiveUserConfigDicts();
        return [
            ...dailyConfigDicts,
            ...txnConfigDicts,
            ...ccuConfigDicts,
            ...newConfigDicts,
            ...newBuyerConfigDicts,
            ...newInstallConfigDicts,
            ...txnUserConfigDicts,
            ...activeUserConfigDicts,
            ...txnCbConfigDicts,
            ...txnShopConfigDicts,
            ...txnShopeeFoodConfigDicts,

        ];
    }

    getDailyConfigDicts() {
        let dailyConfigDicts = [
            {metric: 'order_sbs', reportLabel: 'Order (Service by Shopee)'},
            {
                metric: 'gmv_sbs', reportLabel: 'GMV (Service by Shopee)',
            },
        ];
        return dailyConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
                ...configDict
            }
        });
    }

    getTxnCbConfigDicts() {
        return [
            {
                metric: 'order_cb',
                reportLabel: 'Order (Cross Border)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'order_cb',
                reportLabel: 'Order (Cross Border)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'gmv_cb',
                reportLabel: 'GMV (Cross Border)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'gmv_cb',
                reportLabel: 'GMV (Cross Border)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
        ]
    }

    getTxnShopConfigDicts() {
        return [
            {
                metric: 'order_shop',
                reportLabel: 'Order (Shopee EC Physical)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'order_shop',
                reportLabel: 'Order (Shopee EC Physical)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'gmv_shop',
                reportLabel: 'GMV (Shopee EC Physical)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'gmv_shop',
                reportLabel: 'GMV (Shopee EC Physical)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
        ]
    }

    getTxnShopeeFoodConfigDicts() {
        return [
            {
                metric: 'order_shopeefood',
                reportLabel: 'Order (ShopeeFood)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'order_shopeefood',
                reportLabel: 'Order (ShopeeFood)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'gmv_shopeefood',
                reportLabel: 'GMV (ShopeeFood)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'gmv_shopeefood',
                reportLabel: 'GMV (ShopeeFood)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
        ]
    }

    getTxnConfigDicts() {
        return [
            {
                metric: 'order',
                reportLabel: 'Order',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'order_by_cluster',
                reportLabel: 'By State',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'order_by_telco',
                reportLabel: 'By Telco',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'gmv',
                reportLabel: 'GMV',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'gmv_by_cluster',
                reportLabel: 'By State',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'gmv_by_telco',
                reportLabel: 'By Telco',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'order',
                reportLabel: 'Order',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'order_by_cluster',
                reportLabel: 'By State',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'order_by_telco',
                reportLabel: 'By Telco',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'gmv',
                reportLabel: 'GMV',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'gmv_by_cluster',
                reportLabel: 'By State',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'gmv_by_telco',
                reportLabel: 'By Telco',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
        ];
    }

    getCcuConfigDicts() {
        return [
            {
                metric: 'ccu',
                reportLabel: 'CCU',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeCcuReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'ccu_by_telco',
                reportLabel: 'By Telco',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'ccu_by_cluster',
                reportLabel: 'By State',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'ccu',
                reportLabel: 'PCU',
                frequency: Properties.frequencyBit.daily,
                reportClass: CcuReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'ccu_by_telco',
                reportLabel: 'By Telco',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'ccu_by_cluster',
                reportLabel: 'By State',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
        ]
    }

    getNewConfigDicts() {
        return [
            {
                metric: 'new',
                reportLabel: 'New Register',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'new_by_cluster',
                reportLabel: 'By State',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'new_by_telco',
                reportLabel: 'By Telco',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'new',
                reportLabel: 'New Register',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'new_by_cluster',
                reportLabel: 'By State',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'new_by_telco',
                reportLabel: 'By Telco',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
        ]
    }

    getNewInstallConfigDicts() {
        return [
            {
                metric: 'new_install',
                reportLabel: 'New Install',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'new_install',
                reportLabel: 'New Install',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
        ]
    }

    getNewBuyerConfigDicts() {
        return [
            {
                metric: 'new_buyer',
                reportLabel: 'New Buyer',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'new_buyer',
                reportLabel: 'New Buyer',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
        ]
    }

    getTxnUserConfigDicts() {
        return [
            {
                metric: 'txn_user',
                reportLabel: 'Buyer',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'txn_user_by_telco',
                reportLabel: 'By Telco',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'txn_user_by_cluster',
                reportLabel: 'By State',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'txn_user_new_vs_existing',
                reportLabel: 'New Vs Existing',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'txn_user',
                reportLabel: 'Buyer',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'txn_user_by_telco',
                reportLabel: 'By Telco',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'txn_user_by_cluster',
                reportLabel: 'By State',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'txn_user_new_vs_existing',
                reportLabel: 'New Vs Existing',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
        ]
    }

    getActiveUserConfigDicts() {
        return [
            {
                metric: 'active',
                reportLabel: 'Active User',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'active_by_telco',
                reportLabel: 'By Telco',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
            {
                metric: 'active_by_cluster',
                reportLabel: 'By State',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopee_api',
            },
        ]
    }


    getMatchUrl() {
        return '/shopee';
    }
}

export {ShopeeRouter};