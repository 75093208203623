import React, {Component} from 'react';
import _ from 'lodash';
import AppRoute from './App.Route';
import './App.css';
import './assets/css/app.css';
import './assets/css/Sidebar.css';

import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {BrowserRouter} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ApiPath} from './common/properties'
import {Utils} from './common/utils'
import Header from './components/Header'
import Sidebar from "./views/Sidebar";
import {NPSWrapper} from './components/NPSWrapper';
import 'typeface-roboto';

import moment from "moment";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userMeta: {},
            isWaiting: true,
        }
    }

    componentDidMount() {
        this.changeFavicon();
        this.getMeta();
    }

    changeFavicon() {
        let currentClient = 'keystats';
        let domain = window.location.hostname;
        let possibleClientMap = {
            games: 'game',
            shopeepay: 'shopeepay',
            shopee: 'shopee',
            ocha: 'ocha',
            shopeefood: 'shopeefood',
            seabank: 'seabank',
            credit: 'credit',
            spx: 'spx',
        };

        Object.keys(possibleClientMap).forEach((subApp) => {
            if (domain.includes(subApp)) {
                currentClient = possibleClientMap[subApp];
            }
        });
        document.getElementById('favicon')
            .setAttribute('href', `/icon_${currentClient}.ico`);
    }

    async getMeta() {
        try {
            this.setState({
               isWaiting: true,
            });
            let response = await Utils.getApiResponse(ApiPath.meta);
            response = this.filterMeta(response);
            this.setState({
                userMeta: response
            });
        } catch (err) {
            // if (err instanceof NotLoggedInError) {
            //     window.location.replace(ApiPath.login);
            // }
            // else {
            //     this.setState({
            //         errMessage: 'Invalid User'
            //     })
            // }
        } finally {
            this.setState({
                isWaiting: false
            });
        }
    }

    filterMeta(userMeta) {
        let client = userMeta.client;
        userMeta.data = userMeta.data.filter(datum => {
            let clientsToDisplay = _.get(_.get(datum, 'clients_to_display', {}), 'web', [client]);
            return clientsToDisplay.includes(client);
        });
        return userMeta;
    }

    render() {
        if (!this.state.isWaiting && _.isEmpty(this.state.userMeta)) {
            window.location.replace(ApiPath.login);
        }

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <CssBaseline/>
                <BrowserRouter>
                    <div className='main-page'>
                        {!this.state.isWaiting &&
                            <React.Fragment>
                                {!_.isEmpty(this.state.userMeta) && (
                                    <React.Fragment>
                                        <NPSWrapper/>
                                        <Header meta={this.state.userMeta}/>
                                        <div className={'site'}>
                                            <div className='sidebar'>
                                                <Sidebar userMeta={this.state.userMeta}/>
                                            </div>
                                            <div className='main-component'>
                                                <AppRoute userMeta={this.state.userMeta} />
                                            </div>
                                        </div>
                                    </React.Fragment>)
                                }
                            </React.Fragment>
                        }
                    </div>
                </BrowserRouter>
            </MuiPickersUtilsProvider>
        );
    }
}

export default App;
