import React from 'react';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import _ from 'lodash';

import '../assets/css/RegionChips.css';

function isSelected(region) {
    return region.selected;
}

function isNotSelected(region) {
    return !region.selected;
}

function clickRegionWithPredicate(props, predicate) {
    let regionKeys = props.regions.filter(region => predicate(region)).map(region => region.key);
    props.handleClick(regionKeys);
}

function RegionChips(props) {
    let regions = _.map(props.regions, (region) => {
        return (
            <Chip label={region.label}
                  key={region.key}
                  className={'region-chip pseudo font--semi-bold'}
                  classes={{colorPrimary: 'region-chip--selected', colorSecondary: 'region-chip--unselected'}}
                  onClick={() => props.handleClick([region.key])}
                  variant={'outlined'}
                  color={region.selected ? 'primary' : 'secondary'}/>
        )
    });
    return regions.length > 0 ? (
        <div className={'region-chips font--normal'}>
            <div className={'region-chips__select-country'}>
                Select Region
            </div>
            <Grid item xs={12}>
                {regions}
                {props.isAbleToSelectAll && (
                    <React.Fragment>
                        <span className={'region-chips__divider'}/>
                        <Button className={'region-chips__modify-all modify-all font--normal'}
                                classes={{label: 'modify-all__label'}}
                                onClick={() => {
                                    clickRegionWithPredicate(props, isNotSelected)
                                }}>
                            Select All
                        </Button>
                        <Button className={'region-chips__modify-all modify-all font--normal'}
                                classes={{label: 'modify-all__label'}}
                                onClick={() => clickRegionWithPredicate(props, isSelected)}>
                            Clear All
                        </Button>
                    </React.Fragment>
                )}

            </Grid>
        </div>
    ) : (<React.Fragment/>)
}

export {RegionChips};