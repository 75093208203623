// 'Pseudo' class here just to increase selectivity to override default material UI instead of using !important
import React, {Component} from "react";
import {Loading} from "./Loading";
import {ErrorMessage} from "./ErrorMessage";
import {RegionChips} from "./RegionChips";
import {RadioCategories} from "./RadioCategories";

import _ from 'lodash';

import "../assets/css/ReportSettings.css";
import ListItem from "@material-ui/core/ListItem";
import {ListItemIcon} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import WarningIcon from '../assets/images/icon-warning-notice.png';

class ReportSettings extends Component {

    makeDownloadButton() {
        if (this.props.downloadButton) {
            return this.props.downloadButton;
        }
        return (<React.Fragment/>);
    }

    makeErrorMessage() {
        if (this.props.errorMessage) {
            return (<ErrorMessage error={this.props.errorMessage}/>);
        }
        return (<React.Fragment/>);
    }


    makeAnnouncements() {
        if (this.props.announcements && this.props.announcements.length > 0) {
            return (
                <div className={'announcements-container'}>
                    {this.props.announcements.map((announcement, i) => (
                        <ListItem key={i}>
                            <ListItemIcon>
                                <img src={WarningIcon} alt=''/>
                            </ListItemIcon>
                            <ListItemText disableTypography className={'announcement-typography'} primary={announcement.text}/>
                        </ListItem>
                    ))}
                </div>
            )
        }
        return (<React.Fragment/>);
    }

    render() {
        let regionChipRegions = this.props.regions.map(region => {
            region.selected = this.props.displayRegions[region.key];
            return region;
        });
        return (
            <div className={'report__container'}>
                <div className={'report__title pseudo font--semi-bold'}>
                    {this.props.label}
                </div>
                <div className={'report__separator separator pseudo'}>
                </div>
                <div className={'report__settings-container settings-container pseudo'}>
                    <div className={'settings-container__datepicker-container datepicker-container'}>
                        {this.props.datepicker}
                        {this.makeDownloadButton()}
                        <div>
                            <Loading isLoading={this.props.isLoading}/>
                        </div>
                    </div>
                    <RadioCategories {...this.props.radioCategoriesProps}/>
                    {this.makeErrorMessage()}
                    <RegionChips regions={regionChipRegions}
                                 handleClick={this.props.onRegionToggle}
                                 isAbleToSelectAll={_.get(this.props, 'isAbleToSelectAllRegions', false)}
                    />
                </div>
                {this.makeAnnouncements()}
                {this.props.report}
            </div>
        )
    }

}

export {ReportSettings};
