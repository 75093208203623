import React, {Fragment} from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {ApiErrorUtils, Utils} from "../common/utils";
import {Properties} from "../common/properties";


function download({downloadLink, downloadFileName, frequencyBit, product, downloadDetails, onDownloadFail, onDownloadStart}) {
    let logDownloadLink = `/api/log/download/`,
        postFormData = new FormData()
    ;
    postFormData.append('product', product);
    postFormData.append('frequency_bit', frequencyBit);
    _.forEach(downloadDetails, (val, key) => {
        postFormData.append(key, val);
    });
    onDownloadStart();
    fetch(logDownloadLink, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Utils.getCookie(Properties.apiCookieName)
        },
        body: JSON.stringify(_.assign({
            product,
            frequency_bit: frequencyBit,
        }, downloadDetails)),
    }).then(rawResponse => rawResponse.json())
        .then(response => {
            if (response.error !== null) {
                onDownloadFail(ApiErrorUtils.makeErrorMessage(response.error));
            }
            else {
                const a = document.createElement('a');
                a.style.display = "none";
                document.body.appendChild(a);
                a.href = downloadLink;
                a.setAttribute('download', downloadFileName);
                a.click();
                window.URL.revokeObjectURL(a.href);
                document.body.removeChild(a);
            }
        }, (reason) => onDownloadFail(reason));
}

function DownloadButton(props) {
    return (
        <Fragment>
            <Grid item>
                <Button variant={'contained'} onClick={() => download(props)}
                        classes={{root: 'report-button pseudo', label:'report-button__label font--normal'}}
                >
                    Download
                </Button>
            </Grid>
        </Fragment>
    )
}

export {DownloadButton};