import {createMuiTheme} from "@material-ui/core";

function DatepickerTheme() {
    /*
        For overriding material-ui-picker since there is no way to add css class to the pickers.

        "
            Date/Time pickers are quite simple controls from UX perspective, so most people just use the default appearance
            That's why we are not providing any for-component classes api to override stylesheets for any particular component.
            The only way to override existing stylesheets are with the use of global material-ui theme overrides.
        "
        Source: https://material-ui-pickers.dev/guides/css-overrides
    */
   return createMuiTheme({
        overrides: {
            // Label for the datepicker
            MuiInputLabel: {
                root: {
                    color: '#202325',
                    fontFamily: 'inherit',
                    fontSize: '16px',
                    letterSpacing: 0,
                    lineHeight: '16px',
                }
            },

            // Date box for the datepicker
            MuiInput: {
                underline: {
                    '&:after': {
                        borderBottomColor: '#1B92F5',
                    },
                    '&:before': {
                        content: 'none',
                    },
                }
            },

            MuiInputBase: {
                // Date box
                root: {
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E3E8F6',
                    borderRadius: '2px',
                    boxShadow: 'none',
                    fontFamily: 'inherit',
                    height: '36px',
                    marginTop: '26px !important',
                    marginBottom: '24px',
                    padding: '11px 12px 11px 16px',
                    width: '200px'
                },
                // Date text
                input: {
                    color: '#202325',
                    fontFamily: 'inherit',
                    fontSize: '14px',
                    letterSpacing: 0,
                    lineHeight: '14px'
                }
            },

            // Expandmore arrow box in the date input box
            MuiIconButton: {
                root: {
                    height: '16px',
                    padding: 0,
                    width: '16px'
                },
                label: {
                    height: '16px',
                    width: '16px'
                }
            },

            // Expandmore arrow svg
            MuiSvgIcon: {
                root: {
                    height: '16px',
                    width: '16px',
                    '& path:first-child': {
                        stroke: '#61617B'
                    }
                },
            },

            // Calendar's paper
            MuiPopover: {
                paper: {
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E3E8F6',
                    boxShadow: '0 5px 20px 0 rgba(97,97,123,0.10)',
                    borderRadius: '2px',
                    marginLeft: '14px',
                    marginTop: '14px',
                    padding: '20px 7px 20px 7px !important',
                    width: 'inherit !important'
                }
            },

            // Calendar's dates
            MuiPickersCalendar: {
                transitionContainer: {
                    marginBottom: '-32px',
                    overflow: 'hidden'
                },
            },

            // Calendar's month name
            MuiTypography: {
                body1: {
                    fontFamily: '"Open Sans", "sans-serif"',
                    fontSize: '14px',
                    fontWeight: 600
                }
            },

            // Calendar's day header row
            MuiPickersCalendarHeader: {
                daysHeader: {
                    paddingTop: '36px'
                },
                // For the displaying of day name, everything else beside first letter have 0px size
                dayLabel: {
                    fontSize: '0 !important',
                    margin: '0 3px',
                    width: '32px',
                    '&:first-letter': {
                        fontSize: '13px !important'
                    }
                },
                iconButton: {
                    marginLeft: '13px',
                    marginRight: '13px'
                }
            },

            // Calendar's date
            MuiPickersDay: {
                isSelected: {
                    '&:focus': {
                        backgroundColor: '#047DE2'
                    },
                    '&:hover': {
                        backgroundColor: '#047DE2'
                    },
                    backgroundColor: '#1B92F5',
                    boxShadow: '0 2px 10px 0 rgba(27,146,245,0.30)',
                },
                current: {
                    color: 'inherit',
                    fontWeight: 'normal'
                },
                day: {
                    borderRadius: 0,
                    height: '32px',
                    letterSpacing: 0,
                    lineHeight: '13px',
                    fontFamily: '"Open Sans", "sans-serif"',
                    fontSize: '13px',
                    fontWeight: 400,
                    margin: '0 3px',
                    textAlign: 'center',
                    width: '32px'
                }
            },
        },
    });
}

export {DatepickerTheme};