// 'Pseudo' class here just to increase selectivity to override default material UI instead of using !important
import React, {Component} from "react";
import {Link} from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

class SidebarLink extends Component {
    render() {
        let selected = this.props.link === this.props.additionalData.activeMetricLink;

        return (
            <ListItem alignItems={'center'}
                      button
                      component={(itemProps) => (<Link to={this.props.link} {...itemProps} />)}
                      dense
                      key={'listitem' + this.props.label}
                      className={
                          'metrics__metric metric pseudo metric--' + (selected ? 'selected' : 'unselected')
                      }
                      onClick={() => this.props.additionalData.changeActiveMetricLink(this.props.link)}
            >
                <div
                    className={'metric__colorbar ' +
                               (selected ? 'metric__colorbar--selected' : '')}
                >
                </div>
                <ListItemText primary={this.props.label}
                              classes={{
                                  root: 'pseudo metric__label-box',
                                  primary: 'metric__label pseudo ' +
                                           (selected ? 'metric__label--selected ' : '') +
                                           'font--' + (selected ? 'semi-bold' : 'normal')
                              }}
                />
            </ListItem>
        );
    }
}

export {SidebarLink};