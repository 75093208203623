import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import {GamesRouter} from './views/Games/Games.Routes'
import {AirpayRouter} from "./views/Airpay/Airpay.Routes";
import {ShopeeRouter} from "./views/Shopee/Shopee.Routes";
import {OchaRouter} from "./views/Ocha/Ocha.Routes";
import {FoodyRouter} from "./views/Foody/Foody.Routes";
import {BkeidRouter} from "./views/Money/Bkeid/Bkeid.Routes";
import {BliphRouter} from "./views/Money/Bliph/Bliph.Routes";
import {SeaBankRouter} from "./views/Money/SeaBank/SeaBank.Routes";
import {CreditRouter} from "./views/Credit/Credit.Routes";
import {SPXRouter} from "./views/SPX/SPX.Routes";

class AppRoute extends Component {
    render() {
        return (
            <React.Fragment>
                <Route path={'/games'} render={(props) => (<GamesRouter {...props} {...this.props} />)}/>
                <Route path={'/shopeepay'} render={(props) => (<AirpayRouter {...props} {...this.props} product='shopeepay'/>)}/>
                <Route path={'/shopeefood_web'} render={(props) => (<FoodyRouter {...props} {...this.props} product='shopeefood_web' />)}/>
                <Route path={'/shopee'} render={(props) => (<ShopeeRouter {...props} {...this.props} product='shopee'/>)}/>
                <Route path={'/ocha'} render={(props) => (<OchaRouter {...props} {...this.props} product='ocha'/>)}/>
                <Route path={'/bkeid'} render={(props) => (<BkeidRouter {...props} {...this.props} product='bkeid'/>)}/>
                <Route path={'/bliph'} render={(props) => (<BliphRouter {...props} {...this.props} product='bliph'/>)}/>
                <Route path={'/seabank'} render={(props) => (<SeaBankRouter {...props} {...this.props} product='seabank'/>)}/>
                <Route path={'/credit'} render={(props) => (<CreditRouter {...props} {...this.props} product='credit'/>)}/>
                <Route path={'/spx'} render={(props) => (<SPXRouter {...props} {...this.props} product='spx'/>)}/>
            </React.Fragment>
        );
    }
}

export default AppRoute;