import _ from 'lodash';


class Config {
    static getMetricsFromMetricGroup(metricGroup) {
        const metricGrouping = {
            ccu: [
                'ccu_total_food',
                'ccu_shopee_food'
            ],
            order_gross: [
                'order_total_food_gross',
                'order_shopee_food_gross',
                'order_total_ship_gross',
                'order_shopee_ship_gross',
                'order_total_market_gross',
                'order_shopee_market_gross',
            ],
            order_gross_by_city: [
                'order_total_food_gross_by_city',
                'order_shopee_food_gross_by_city',
                'order_total_ship_gross_by_city',
                'order_shopee_ship_gross_by_city',
                'order_total_market_gross_by_city',
                'order_shopee_market_gross_by_city',
            ],
            order_net: [
                'order_total_food_net',
                'order_shopee_food_net',
                'order_total_ship_net',
                'order_shopee_ship_net',
                'order_total_market_net',
                'order_shopee_market_net',
            ],
            order_net_by_city: [
                'order_total_food_net_by_city',
                'order_shopee_food_net_by_city',
                'order_total_ship_net_by_city',
                'order_shopee_ship_net_by_city',
                'order_total_market_net_by_city',
                'order_shopee_market_net_by_city',
            ],
            gmv_net: [
                'gmv_total_food_net',
                'gmv_shopee_food_net',
                'gmv_total_market_net',
                'gmv_shopee_market_net',
            ],
            gmv_net_by_city: [
                'gmv_total_food_net_by_city',
                'gmv_shopee_food_net_by_city',
                'gmv_total_market_net_by_city',
                'gmv_shopee_market_net_by_city',
            ],
            new_buyer: [
                'new_buyer_total_food',
                'new_buyer_shopee_food',
                'new_buyer_total_market',
                'new_buyer_shopee_market',
            ],
            new_buyer_by_city: [
                'new_buyer_total_food_by_city',
                'new_buyer_shopee_food_by_city',
                'new_buyer_total_market_by_city',
                'new_buyer_shopee_market_by_city',
            ],
            txn_user: [
                'txn_user_total_food',
                'txn_user_total_ship',
                'txn_user_shopee_food',
            ],
            txn_user_by_city: [
                'txn_user_total_ship_by_city',
            ],
        };
        return _.get(metricGrouping, metricGroup, []);
    }

    static getMetric(metricGroup, subtype) {
        let metrics = this.getMetricsFromMetricGroup(metricGroup);
        return _.find(metrics, (metric) => metric.includes(subtype));
    }
}

export {Config}
