import _ from 'lodash';
import {Utils} from "../common/utils";

import {DailyReport} from "../views/DailyReport";


class MultiMetricsDailyReport extends DailyReport {

    getMetrics() {
        return [];
    }

    getMetricHeader() {
        return {};
    }

    getMetricFormatter() {
        return {};
    }

    getEarliestDate(regionKey = '') {
        return _.get(this.state.earliestDate, regionKey, this.getEarliestDateConstant());
    }

    getTableData() {
        let tableData = {};
        this.getFilteredRegions().forEach(region => {
            let header = this.getRegionTableHeaderData(region);
            let secondHeader = this.getRegionTableSecondHeaderData(region);
            let content = this.getRegionTableContent(region);
            tableData[region.key] = {
                header: header,
                secondHeader: secondHeader,
                content: content
            };
        });
        return tableData;
    }

    getRegionTableHeaderData(region) {
        let header = [];
        header.push({
            key: region.key,
            content: region.label,
            className: `${this.getHeaderCellClassName()} header__table-region`,
            rowSpan: 2,
            align: 'center'
        });
        this.getMetrics().forEach(metric => {
            let label = this.getTableMetricHeader(metric);
            let colspan = this.getTableMetricColSpan(metric, region);

            if (colspan === 0) {
                return;
            }

            header.push({
                key: label,
                content: label,
                align: 'center',
                rowSpan: this.needDoubleRowSpan(metric, region) ? 2 : 1,
                colSpan: colspan,
                className: `${this.getHeaderCellClassName()}`
            });

            // For the 'empty' cell in the csv (will be ignored by the html since isHidden = true)
            header = this.addEmptyHiddenCell(header, colspan - 1);
        });

        return header;
    }

    getHeaderCellClassName() {
        return super.getHeaderCellClassName() + ' table__cell--left-border';
    }

    getSecondHeaderCellClassName() {
        return this.getNormalCellClassName() + ' header__cell';
    }

    getNormalCellClassName() {
        return super.getNormalCellClassName() + ' table__cell--small';
    }

    getTableMetricHeader(metric) {
        return _.get(this.getMetricHeader()[metric], 'headerLabel', metric);
    }

    getTableMetricColSpan(metric) {
        return this.getMetricPeriods(metric).length;
    }

    getMetricPeriods(metric) {
        return _.get(this.state.metricPeriods, metric, []);
    }

    needDoubleRowSpan(metric) {
        return this.getTableMetricColSpan(metric) === 1;
    }

    addEmptyHiddenCell(rowToAdd, repeatTimes) {
        if (repeatTimes <= 0) {
            return rowToAdd;
        }
        rowToAdd.push({
            content: '',
            key: Math.random().toString(),
            isHidden: true
        });
        rowToAdd = this.addEmptyHiddenCell(rowToAdd, repeatTimes-1);
        return rowToAdd;
    }

    getRegionTableSecondHeaderData(region) {
        let secondHeader = [];

        // For the csv, in the html it will be ignored
        secondHeader.push({
            key: 'date_title',
            content: 'DATE',
            isHidden: true,
        });

        this.getMetrics().forEach(metric => {
            if (this.needDoubleRowSpan(metric)) {
                secondHeader = this.addEmptyHiddenCell(secondHeader, 1);
                return;
            }

            secondHeader = secondHeader.concat(this.getMetricPeriods(metric).map(period => {
                let label = this.getTableMetricSecondHeader(metric, period);
                let borderClassname = this.needLeftBorder(metric, region, period) ? ' table__cell--left-border' : '';
                return {
                    content: label,
                    key: metric + label,
                    align: 'left',
                    className: this.getSecondHeaderCellClassName() + borderClassname
                }
            }));
        });

        return secondHeader;
    }

    needLeftBorder(metric, region, period) {
        let metricPeriods = this.getMetricPeriods(metric, region);
        return period === metricPeriods[0];
    }

    getTableMetricSecondHeader(metric, period) {
        let prefix = _.get(this.getMetricHeader()[metric], 'secondHeaderPrefix', '');
        let suffix = _.get(this.getMetricHeader()[metric], 'secondHeaderSuffix', '');
        return prefix + period.toString() + suffix;
    }

    getRegionTableContent(region) {

        let earliestDate = this.getEarliestDate(region.key);
        let contentRows;

        contentRows = this.getDates(earliestDate).map(date => {
            let contentRow = [];
            contentRow.push({
                key: date,
                content: date,
                className: this.getNormalCellClassName(),
                align: 'left'
            });

            this.getMetrics().forEach(metric => {
                contentRow = contentRow.concat(this.getMetricPeriods(metric, region).map(period => {
                    let leftBorderClassName = this.needLeftBorder(metric, region, period) ? ' table__cell--left-border' : '';
                    let cell = this.getValueFromRegionDataDate(metric, date, region, period);
                    cell.className = cell.className + leftBorderClassName;
                    return cell;
                }));
            });

            return contentRow;
        });
        return contentRows;
    }

    getValueFromRegionDataDate(metric, date, region, period) {
        let dataKey = this.makeDataDictKey(metric, this.getDataKey(), date, region.key, period);
        let val = _.get(this.state.dataDict, dataKey, '');
        if (val !== '') {
            let defaultMetricFormatter = val => Utils.formatCommaSeparatedNumber(val);
            let metricFormatter = _.get(this.getMetricFormatter(), metric, defaultMetricFormatter);
            val = metricFormatter(val);
        }
        return {
            content: val,
            key: metric.toString() + period.toString(),
            className: this.getNormalCellClassName(),
            align: 'left'
        };
    }

    getCsvRowsContents() {
        let rows = [];
        let tableData = this.getTableData();
        this.getFilteredRegions().forEach(region => {
            rows = rows.concat(this.makeCsvRowsFromTableData(_.get(tableData, region.key, {})));
            rows.push([]);
        });
        return rows;
    }

    getHeaderRowClassName() {
        return super.getHeaderRowClassName() + ' header__row--small';
    }

    makeReport(){
        let tableData = this.getTableData();
        return this.getFilteredRegions().map(region => this.renderTable(_.get(tableData, region.key, {}), region.key));
    }
}

export {MultiMetricsDailyReport};