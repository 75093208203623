import React, {Component} from 'react';
import _ from 'lodash';
import {Properties} from "../../common/properties";
import {UserMetaUtils} from "../../common/utils";
import {Section} from "../../components/SidebarSection";


class AirpaySidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
        };
    }

    hasMetric(product, metric, frequencyBit) {
        return Boolean(this.props.userMeta.meta_data[product] &&
            UserMetaUtils.getMetaMetricKey(metric, frequencyBit) in this.props.userMeta.meta_data[product].metrics);
    }

    getTxnUserConfigDicts(airpayConfig) {
        return [
            {config: airpayConfig, metric: 'txn_user', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn User', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_mp', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn User (ShopeePay - Shopee (non-DP/Food))', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_dp', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn User (ShopeePay - DP)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_food', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn User (ShopeePay - ShopeeFood)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_mitra', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn User (ShopeePay - Mitra)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_garena', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn User (ShopeePay - Garena)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_credit_repayment', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn User (ShopeePay - Credit Repayment)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_other_dfs', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn User (ShopeePay - Other DFS)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_p2p', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn User (ShopeePay - P2P)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_p2moffline', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn User (ShopeePay - P2M Offline)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_p2monline', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn User (ShopeePay - P2M Online)', metricGroup: 'Txn User'},

            {config: airpayConfig, metric: 'txn_user', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn User', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_mp', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn User (ShopeePay - Shopee (non-DP/Food))', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_dp', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn User (ShopeePay - DP)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_food', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn User (ShopeePay - ShopeeFood)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_mitra', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn User (ShopeePay - Mitra)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_garena', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn User (ShopeePay - Garena)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_credit_repayment', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn User (ShopeePay - Credit Repayment)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_other_dfs', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn User (ShopeePay - Other DFS)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_p2p', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn User (ShopeePay - P2P)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_p2moffline', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn User (ShopeePay - P2M Offline)', metricGroup: 'Txn User'},
            {config: airpayConfig, metric: 'txn_user_p2monline', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn User (ShopeePay - P2M Online)', metricGroup: 'Txn User'},
        ]
    }

    getTxnConfigDicts(airpayConfig) {
        return [
            {config: airpayConfig, metric: 'txn', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_mp', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn (ShopeePay - Shopee (non-DP/Food))', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_dp', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn (ShopeePay - DP)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_food', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn (ShopeePay - ShopeeFood)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_mitra', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn (ShopeePay - Mitra)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_garena', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn (ShopeePay - Garena)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_credit_repayment', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn (ShopeePay - Credit Repayment)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_other_dfs', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn (ShopeePay - Other DFS)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_p2p', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn (ShopeePay - P2P)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_p2moffline', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn (ShopeePay - P2M Offline)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_p2monline', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime Txn (ShopeePay - P2M Online)', metricGroup: 'Txn'},

            {config: airpayConfig, metric: 'txn', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_mp', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn (ShopeePay - Shopee (non-DP/Food))', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_dp', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn (ShopeePay - DP)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_food', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn (ShopeePay - ShopeeFood)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_mitra', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn (ShopeePay - Mitra)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_garena', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn (ShopeePay - Garena)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_credit_repayment', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn (ShopeePay - Credit Repayment)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_other_dfs', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn (ShopeePay - Other DFS)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_p2p', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn (ShopeePay - P2P)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_p2moffline', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn (ShopeePay - P2M Offline)', metricGroup: 'Txn'},
            {config: airpayConfig, metric: 'txn_p2monline', frequencyBit: Properties.frequencyBit.daily,
                label: 'Txn (ShopeePay - P2M Online)', metricGroup: 'Txn'},
        ]
    }

    getTpvConfigDicts(airpayConfig) {
        return [
            {config: airpayConfig, metric: 'tpv', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime TPV', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_mp', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime TPV (ShopeePay - Shopee (non-DP/Food))', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_dp', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime TPV (ShopeePay - DP)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_food', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime TPV (ShopeePay - ShopeeFood)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_mitra', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime TPV (ShopeePay - Mitra)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_garena', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime TPV (ShopeePay - Garena)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_credit_repayment', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime TPV (ShopeePay - Credit Repayment)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_other_dfs', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime TPV (ShopeePay - Other DFS)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_p2p', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime TPV (ShopeePay - P2P)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_p2moffline', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime TPV (ShopeePay - P2M Offline)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_p2monline', frequencyBit: Properties.frequencyBit.realtime,
                label: 'Realtime TPV (ShopeePay - P2M Online)', metricGroup: 'TPV'},

            {config: airpayConfig, metric: 'tpv', frequencyBit: Properties.frequencyBit.daily,
                label: 'TPV', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_mp', frequencyBit: Properties.frequencyBit.daily,
                label: 'TPV (ShopeePay - Shopee (non-DP/Food))', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_dp', frequencyBit: Properties.frequencyBit.daily,
                label: 'TPV (ShopeePay - DP)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_food', frequencyBit: Properties.frequencyBit.daily,
                label: 'TPV (ShopeePay - ShopeeFood)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_mitra', frequencyBit: Properties.frequencyBit.daily,
                label: 'TPV (ShopeePay - Mitra)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_garena', frequencyBit: Properties.frequencyBit.daily,
                label: 'TPV (ShopeePay - Garena)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_credit_repayment', frequencyBit: Properties.frequencyBit.daily,
                label: 'TPV (ShopeePay - Credit Repayment)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_other_dfs', frequencyBit: Properties.frequencyBit.daily,
                label: 'TPV (ShopeePay - Other DFS)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_p2p', frequencyBit: Properties.frequencyBit.daily,
                label: 'TPV (ShopeePay - P2P)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_p2moffline', frequencyBit: Properties.frequencyBit.daily,
                label: 'TPV (ShopeePay - P2M Offline)', metricGroup: 'TPV'},
            {config: airpayConfig, metric: 'tpv_p2monline', frequencyBit: Properties.frequencyBit.daily,
                label: 'TPV (ShopeePay - P2M Online)', metricGroup: 'TPV'},
        ]
    }

    makeLinks(airpayConfig) {
        return _.filter([
            {config: airpayConfig, metric: 'ccu', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime CCU'},
            {config: airpayConfig, metric: 'ccu', frequencyBit: Properties.frequencyBit.daily, label: 'PCU'},
            {config: airpayConfig, metric: 'new_register', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime New Register', metricGroup: "New"},
            {config: airpayConfig, metric: 'new_register', frequencyBit: Properties.frequencyBit.daily, label: 'New Register', metricGroup: "New"},
            {config: airpayConfig, metric: 'new_kyc', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime New KYC', metricGroup: "New"},
            {config: airpayConfig, metric: 'new_kyc', frequencyBit: Properties.frequencyBit.daily, label: 'New KYC', metricGroup: "New"},
            {config: airpayConfig, metric: 'new_txn_user', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime New Txn User', metricGroup: "New"},
            {config: airpayConfig, metric: 'new_txn_user', frequencyBit: Properties.frequencyBit.daily, label: 'New Txn User', metricGroup: "New"},

            ...this.getTxnUserConfigDicts(airpayConfig),
            ...this.getTxnConfigDicts(airpayConfig),
            ...this.getTpvConfigDicts(airpayConfig),
        ], ({config, metric, frequencyBit}) => this.hasMetric(config.product, metric, frequencyBit));
    }

    render() {
        let airpayConfig;
        if (this.props.userMeta.data) {
            for (let i = 0; i < this.props.userMeta.data.length; i++) {
                let productConfig = this.props.userMeta.data[i];
                if (productConfig.product_group === 'shopeepay_api') {
                    airpayConfig = productConfig;
                    break;
                }
            }
        }
        return (
            <React.Fragment>
                {airpayConfig && <Section key={'section' + airpayConfig.label}
                                          productType={'shopeepay'}
                                          links={this.makeLinks(airpayConfig)}
                                          label={airpayConfig.label}
                                          additionalData={this.props.additionalData}
                                 />}
            </React.Fragment>
        )
    }
}

export {AirpaySidebar};