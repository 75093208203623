import React, {Component} from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import _ from 'lodash';

import "../assets/css/RadioCategories.css";

class RadioCategories extends Component {
    createRadioFromConfig(config) {
        return (
            <FormControlLabel
                value={config.key}
                title={config.label}
                key={config.key}
                control={<Radio/>}
                label={
                    (<div className={'radio__label font--normal'}
                          title={config.label}
                    >
                        {config.label}
                    </div>)
                }
                className={'radio__button ' +
                (this.props.value === config.key
                        ? 'radio__button--selected'
                        : ''
                )}
            />
        )
    }

    render() {
        const configs = _.get(this.props, 'configs', []);
        if (configs.length <= 1) {
            return (<React.Fragment/>);
        }
        return (
            <div className={'radio-categories'}>
                <div className={'radio-categories__title'}>
                    {this.props.title}
                </div>
                <RadioGroup
                    name={'radio-group'}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    className={'radio-categories__radio-group'}
                >
                    {configs.map((config) => this.createRadioFromConfig(config))}
                </RadioGroup>
            </div>
        );
    }
}

export {RadioCategories}
