import {Properties} from './properties'
import {NotLoggedInError, ApiError} from "./error";
import _ from 'lodash';
import moment from 'moment';

class ApiErrorUtils {
    static codes = {
        notLoggedIn: 4,
        notPermitted: [2, 3, 4]
    };

    static makeErrorMessage(errorCode) {
        return `Something went wrong ${errorCode}`;
    }

    static makeError(errorCode) {
        if (errorCode === ApiErrorUtils.codes.notLoggedIn) {
            return new NotLoggedInError();
        }
        else {
            return new ApiError('Something went wrong')
        }
    }
}

class Utils {
    static getApiResponse(url, params = {}) {
        params = {
            ...params,
            'client_type': 'web'
        };
        return fetch(Utils.makeGetRequestUrl(url, params), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Utils.getCookie(Properties.apiCookieName)
            }
        }).then(rawResponse => rawResponse.json())
            .then(response => {
                if (response.error !== null) {
                    throw ApiErrorUtils.makeError(response.error);
                }
                return response;
            });
    }

    static getApiPath({metric, product, frequencyBit}) {
        return `/api/${Properties.frequencyMap[frequencyBit]}/${product}/${metric}/`;
    }

    static getUrlPath({matchUrl, metric, product, frequencyBit}) {
        return `${matchUrl}/${Properties.frequencyMap[frequencyBit]}/${metric}/${product}`
    };

    static makeGetRequestUrl(url, params) {
        let paramsArr = [];
        _.forEach(params, (val, key) => {
            if (Array.isArray(val)) {
                paramsArr.push(_.join(_.map(val, (arrVal) => `${key}=${arrVal}`), '&'));
            } else {
                paramsArr.push(`${key}=${val}`);
            }
        });
        return `${url}?` + _.join(paramsArr, '&');
    }

    static getCookie(cookieName) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            let cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, cookieName.length + 1) === (cookieName + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(cookieName.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    static getTimestampFromMinute(minute) {
        if (minute === 1440) {
            return '24:00'; // differentiating 00:00 and 24:00
        }
        return moment({hour: 0}).minutes(minute).format('HH:mm');
    }

    static formatNumberShortform(number) {
        let sign = number >= 0 ? 1: -1
        number = Math.abs(number)
        let label = number;
        if (number >= Math.pow(10, 3) && number < Math.pow(10, 6)) {
            label = _.round(number / Math.pow(10, 3), 1) + 'k';
        } else if (number >= Math.pow(10, 6) && number < Math.pow(10, 9)) {
            label = _.round(number / Math.pow(10, 6), 1) + 'm';
        } else if (number >= Math.pow(10, 9) && number < Math.pow(10, 12)) {
            label = _.round(number / Math.pow(10, 9), 1) + 'b';
        } else if (number >= Math.pow(10, 12) && number < Math.pow(10, 15)) {
            label = _.round(number / Math.pow(10, 12), 2) + 't';
        } else if (number >= Math.pow(10, 15)) {
            label = _.round(number / Math.pow(10, 15), 1) + 'qd';  // quadrillion
        }
        label = sign === 1? label: '-' + label
        return label;
    }

    static adaptiveRound(val, dp=undefined) {
        if (isNaN(val)) {
            return val;
        }
        if (!isNaN(dp)) {
            return _.round(val, dp)
        }
        if (Math.abs(val) < 1) {
            return _.round(val, 2);
        } else {
            return _.round(val, 1);
        }
    }

    static formatCommaSeparatedNumber(number, dp=undefined) {
        if (isNaN(number)) {
            return number;
        }
        let val = Utils.adaptiveRound(number, dp),
            currVal = Math.abs(val),
            valParts = [],
            prefix = number >= 0 ? '' : '-';
        while (currVal > 0) {
            let remainder = currVal % 1000;
            valParts.unshift(remainder);
            currVal = Math.trunc(currVal / 1000);
        }
        if (!_.isEmpty(valParts)) {
            valParts[valParts.length - 1] = Utils.adaptiveRound(valParts[valParts.length - 1], dp);
        } else {
            valParts.push(Utils.adaptiveRound(val, dp));
        }
        return prefix + _.join(_.map(valParts, (valPart, i) => i > 0 ? _.padStart(valPart, 3, '0') : valPart), ',');
    }

    static formatMomentForApi(datetime, frequencyBit) {
        return datetime.format(Properties.frequencyDateFormat[frequencyBit]);
    }

    static createCsv(rows) {
        let content = _.join(_.map(rows, (row) => _.join(row, '","')), '"\n"'),
            blob = new Blob([`"${content}"`], {type: 'text/csv'})
        ;
        return URL.createObjectURL(blob);
    }
}

class UserMetaUtils {

    static getMetaMetricKey(metric, frequencyBit) {
        return `${metric}|||${frequencyBit}`;
    }
}

export {Utils, UserMetaUtils, ApiErrorUtils};