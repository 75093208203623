import React, {Component} from 'react';
import NPS from '@seaweb/nps';
import { NPSSchedule, createActionBasedTimeOffset } from '@seaweb/nps-scheduler';

class NPSWrapper extends Component {
    componentDidMount() {
        const clientKey = process.env.NPS_CLIENT_KEY;
        const baseUrl = process.env.NPS_BASE_URL;

        const nps = NPS({ clientKey, baseUrl });
        const getTimeOffset = createActionBasedTimeOffset(nps);
        NPSSchedule(nps, { getTimeOffset });
    }
    render() {
        return <React.Fragment/>;
    }
}

export {NPSWrapper};
