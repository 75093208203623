import {AbstractRouter} from "../Router";
import {RealtimeReport} from '../RealtimeReport';
import {DailyReport} from "../DailyReport";
import {DateRangeReport} from "../DateRangeReport";
import {MonthlyReport } from "../MonthlyReport";
import {Properties} from "../../common/properties";


class SPXRouter extends AbstractRouter {
    getConfigDicts() {
        let realtimeConfigDicts = this.getRealtimeConfigDicts();
        let dailyConfigDicts = this.getDailyConfigDicts();
        let monthlyConfigDicts = this.getMonthlyConfigDicts();
        let rangeConfigDicts = this.getRangeConfigDicts();
        return [
            ...realtimeConfigDicts,
            ...dailyConfigDicts,
            ...monthlyConfigDicts,
            ...rangeConfigDicts,
        ];
    }

    getRealtimeConfigDicts() {
        let realtimeConfigDicts = [
            {metric: 'seller_arranged_order', reportLabel: 'Seller Arranged Order'},
            {metric: 'fulfilled_order', reportLabel: 'Fulfilled Order'},
        ];
        return realtimeConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
                productGroup: 'spx_api',
                ...configDict
            }
        });
    }

    getDailyConfigDicts() {
        let dailyConfigDicts = [
            {metric: 'seller_arranged_order', reportLabel: 'Seller Arranged Order'},
            {metric: 'fulfilled_order', reportLabel: 'Fulfilled Order'},
        ];
        return dailyConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'spx_api',
                ...configDict
            }
        });
    }

    getMonthlyConfigDicts() {
        let monthlyConfigDicts = [
            {metric: 'seller_arranged_order', reportLabel: 'Seller Arranged Order'},
            {metric: 'fulfilled_order', reportLabel: 'Fulfilled Order'},
        ];
        return monthlyConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.monthly,
                reportClass: MonthlyReport,
                productGroup: 'spx_api',
                ...configDict
            }
        });
    }

    getRangeConfigDicts() {
        let rangeConfigDicts = [
            {metric: 'local_cdt', reportLabel: 'Local CDT'},
            {metric: 'lost_damaged_order', reportLabel: 'Lost & Damaged'},
        ];
        return rangeConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.dateRange,
                reportClass: DateRangeReport,
                productGroup: 'spx_api',
                ...configDict
            }
        });
    }
    

    getMatchUrl() {
        return '/spx';
    }
}

export {SPXRouter};