import _ from 'lodash';
import {RealtimeReport} from "../../RealtimeReport"

import "../../../assets/css/RealtimeReport.css"

class RealtimeCcuReport extends RealtimeReport {
    getConstructorStates() {
        const platforms = this.getPlatforms();
        const defaultPlatform = _.first(platforms.map(subtype => subtype.key));
        return {
            platform: defaultPlatform,
            ...super.getConstructorStates(),
        };
    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        super.componentDidUpdate(prevProps, prevState, snapShot);
        let prevPlatform = _.get(prevState, 'platform', undefined);
        let currentPlatform = _.get(this.state, 'platform', undefined);

        if (currentPlatform !== prevPlatform) {
            this.getAndProcessData().then();
        }
    }

    getMetricData(metric) {
        metric = this.state.platform;
        return super.getMetricData(metric);
    }

    getPlatforms() {
        return [
            {
                "key": "ccu",
                "label": "All",
            },
            {
                "key": "ccu_ios",
                "label": "iOS",
            },
            {
                "key": "ccu_android",
                "label": "Android",
            }
        ]
    }

    onPlatformChange(event) {
        let newPlatform = event.target.value;
        this.setState({
            platform: newPlatform,
            dataDict: {},
        });
    }

    getRadioCategoriesProps() {
        return {
            configs: this.getPlatforms(),
            value: _.get(this.state, 'platform', undefined),
            onChange: (event) => this.onPlatformChange(event),
            title: 'Select Platform',
        }
    }

    makeLabel(appLabel, reportLabel) {
        let platformLabel = _.find(this.getPlatforms(), platform => platform.key === this.state.platform).label;
        return super.makeLabel(appLabel, reportLabel) + ` (${platformLabel})`;
    }

}

export {RealtimeCcuReport}
