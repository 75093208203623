import {AbstractRouter} from "../Router";
import {DailyReport} from "../DailyReport";
import {Properties} from "../../common/properties";

class OchaRouter extends AbstractRouter {

    getConfigDicts() {
        let configDicts = [
            {metric: 'order', reportLabel: 'Order'},
            {
                metric: 'gmv', reportLabel: 'GMV',
            },
            {metric: 'active_shop', reportLabel: 'Active Shop'},
            {metric: 'paying_shop', reportLabel: 'Paying Shop'},
        ];
        return configDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'ocha_api',
                ...configDict
            }
        })
    }

    getMatchUrl() {
        return '/ocha';
    }
}

export {OchaRouter};