// 'Pseudo' class here just to increase selectivity to override default material UI instead of using !important
import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import * as moment from 'moment-timezone';
import { InlineDatePicker } from 'material-ui-pickers';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Properties } from '../common/properties';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Field from '@seaweb/coral/components/Field';
import RangePicker, {
  RangePickerCalendarLeft,
  RangePickerCalendarRight,
  RangePickerInput,
  RangePickerInputChildren,
  RangePickerInputEnd,
  RangePickerInputStart,
  RangePickerPopup,
  RangePickerPopupChildren,
} from '@seaweb/coral/components/RangePicker';

import { DatepickerTheme } from '../assets/JssTheme/DatepickerTheme.jsx';
import '../assets/css/Datepicker.css';
import _ from 'lodash';

var isoWeek = require('dayjs/plugin/isoWeek');
dayjs.extend(isoWeek);

function makeDatepicker(outerKey, innerKey, label, onDateChange, startValue) {
  return (
    <Grid item className={'datepicker font--normal pseudo'} key={outerKey}>
      <MuiThemeProvider theme={DatepickerTheme()}>
        <InlineDatePicker
          disableOpenOnEnter
          format={Properties.frequencyDateFormat[Properties.frequencyBit.daily]}
          key={innerKey}
          keyboard
          keyboardIcon={<ExpandMore />}
          label={label}
          mask={(value) => (value ? [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/] : [])}
          onChange={(date) => onDateChange(date)}
          onlyCalendar={true}
          value={startValue}
        />
      </MuiThemeProvider>
    </Grid>
  );
}

function makeDatepickerSubmitButton(props) {
  return (
    <Grid item>
      <Button
        variant={'contained'}
        color={'primary'}
        onClick={() => props.handleClick()}
        classes={{ root: 'report-button pseudo', label: 'report-button__label font--normal' }}
      >
        Submit
      </Button>
    </Grid>
  );
}

function DailyDatepicker(props) {
  return (
    <Fragment>
      {makeDatepicker(
        'startDate',
        'startDateDatepicker',
        'Start Date',
        (date) => props.handleDateChange('startDate', date),
        props.startDate
      )}
      <div className={'datepicker__line'}> </div>
      {makeDatepicker(
        'endDate',
        'endDateDatepicker',
        'End Date',
        (date) => props.handleDateChange('endDate', date),
        props.endDate
      )}
      {makeDatepickerSubmitButton(props)}
    </Fragment>
  );
}

function RealtimeDatepicker(props) {
  let datepickers = _.map(props.dates, (pickedDate, i) => {
    return makeDatepicker(i, `day${i}`, `Day ${i < 2 ? i : 7}`, (date) => props.onDateChange(i, date), pickedDate);
  });
  return (
    <Fragment>
      {datepickers}
      {makeDatepickerSubmitButton(props)}
    </Fragment>
  );
}

function DateRangePicker(props) {
  const { startDate, endDate, handleDateChange } = props;

  return (
    <Fragment>
      <Field
        label="Select Week Range"
        labelProps={{
          style: {
            fontFamily: '"Open Sans", "sans-serif"',
            fontSize: '12px',
            color: '#202325',
          },
        }}
        style={{ paddingLeft: 7, width: 'unset', margin: '4px 7px 24px 0' }}
      >
        <RangePicker
          displayDate={(value) => {
            if (value) {
              const start = value.isoWeekday(1);
              const end = start.add(6, 'days');
              return `${start.format('MM/DD')} - ${end.format('MM/DD')}`;
            }
            return '';
          }}
          value={[startDate ? dayjs(startDate) : null, endDate ? dayjs(endDate) : null]}
          inputElement={
            <RangePickerInput>
              <RangePickerInputChildren
                inputStart={<RangePickerInputStart placeholder="Start week" style={{ zIndex: 0 }} />}
                inputEnd={<RangePickerInputEnd placeholder="End week" style={{ zIndex: 0 }} />}
              />
            </RangePickerInput>
          }
          popupElement={
            <RangePickerPopup>
              <RangePickerPopupChildren
                calendarLeft={<RangePickerCalendarLeft startOfWeek={1} />}
                calendarRight={<RangePickerCalendarRight startOfWeek={1} />}
              />
            </RangePickerPopup>
          }
          onChange={(dates) => {
            const start = dates[0].isoWeekday(1);
            const startDate = moment(start.format(Properties.frequencyDateFormat[Properties.frequencyBit.dateRange]));
            const end = dates[1] ? dates[1].isoWeekday(1).add(6, 'days') : dates[0].isoWeekday(1).add(6, 'days');
            const endDate = moment(end.format(Properties.frequencyDateFormat[Properties.frequencyBit.dateRange]));
            handleDateChange('startDate', startDate);
            handleDateChange('endDate', endDate);
          }}
          isDateDisabled={(date) => date.isAfter(dayjs().isoWeekday(1).add(-1, 'days'))}
        />
      </Field>

      {makeDatepickerSubmitButton(props)}
    </Fragment>
  );
}

export { DailyDatepicker, RealtimeDatepicker, DateRangePicker };
