import _ from 'lodash';
import {Utils} from "../../common/utils";

import {MultiMetricsDailyReport} from "../MultiMetricsDailyReport";

const METRICS_WITH_SUFFIX = ['revenue', 'arppu'];
const METRICS_WITHOUT_SUFFIX = ['paying_user', 'penetration'];
const METRICS = METRICS_WITH_SUFFIX.concat(METRICS_WITHOUT_SUFFIX);
const METRIC_FORMATTER = {
    'arppu_gross': val => '$' + Utils.adaptiveRound(val),
    'revenue_gross': val => '$' + Utils.adaptiveRound(Utils.formatCommaSeparatedNumber(val)),
    'arppu_net': val => '$' + Utils.adaptiveRound(val),
    'revenue_net': val => '$' + Utils.adaptiveRound(Utils.formatCommaSeparatedNumber(val)),
    'penetration': val => Utils.adaptiveRound(val * 100) + '%'
};
const METRIC_HEADER = {
    revenue_gross: {
        headerLabel: 'Rev($)',
    },
    revenue_net: {
        headerLabel: 'Rev($)',
    },
    arppu_gross: {
        headerLabel: 'ARPPU($)',
        secondHeaderSuffix: 'D'
    },
    arppu_net: {
        headerLabel: 'ARPPU($)',
        secondHeaderSuffix: 'D'
    },
    paying_user: {
        headerLabel: 'Unique Topup User',
        secondHeaderSuffix: 'D'
    },
    penetration: {
        headerLabel: 'Penetration',
        secondHeaderPrefix: 'P'
    }
};

class GamesRevenueReport extends MultiMetricsDailyReport {
    getMetrics() {
        return METRICS.map(metric => this.getMetricKey(metric));
    }

    getMetricKey(metric) {
        if (_.includes(METRICS_WITH_SUFFIX, metric)) {
            return _.includes(this.props.match.path, 'revenue_gross') ? metric + '_gross' : metric + '_net';
        }
        return metric;
    }

    getMetricHeader() {
        return METRIC_HEADER;
    }

    getMetricFormatter() {
        return METRIC_FORMATTER;
    }
}

export {GamesRevenueReport};